@import "../../style/setup/variables.scss";

.empty-page-cmp {
  margin-top: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  h1 {
    color: $clrNoData1;
    font-family: main-font-extra-bold;
    font-size: 80px;
  }
}
