@import "../../style/setup/variables.scss";

.TableFilter {
  position: sticky;
  top: 70px;
  display: flex;
  flex-direction: column;
  background: $orangeTableHeader;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 10;
  .filters {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    .titleContainer {
      padding: $normalPadding;
      gap: $normalPadding;
      display: flex;
      justify-content: space-between;
      .icon {
        cursor: pointer;
        color: black;
      }
    }
    .filtersField {
      display: flex;
      gap: 16px;
      align-items: center;
      .dropdown {
        position: relative;
        width: 150px;
        .selectedLabel {
          width: 150px;
        }
      }
      .field {
        position: relative;
        cursor: pointer;
        white-space: nowrap;
        width: 100%;
        font-family: main-font-regular;
        font-size: 1rem;
        color: #222936;
        border-radius: 6px;
        border: 1px solid wheat;
        background-color: wheat;
        outline: none;
        text-align: end;
        transition: background-color 0.5s ease;
        .label {
          padding: 6px;
        }
        &.rtl {
          direction: rtl;
        }
        &.ltr {
          direction: ltr;
        }
        &.active {
          background: whitesmoke;
        }
        &:hover {
          background: $whitesmoke;
        }
      }
    }
    .searchComponent {
      display: flex;
      gap: 8px;
      margin-right: 20px;
      .rowCount {
        align-self: center;
        font-size: 1.8rem;
        font-weight: 500;
      }
      .search-bar {
        width: 250px;
        input {
          width: 100%;
        }
      }
    }
  }
  .filtersAndActions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: $normalPadding;
    .actionsContainer {
      display: flex;
      flex-direction: row;
      gap: $normalPadding;
      margin: 0px 8px;
      font-size: 1.5rem;
      .actions {
        display: flex;
        padding: 0rem 1rem;
        gap: 6px;
        .action {
          font-size: 1.2rem;
          cursor: pointer;
          color: $clrTextMediumGrey;
        }
        .action:hover {
          color: $clrBlack;
        }
      }
    }
    .visualFilters {
      height: 30px;
      margin: 0px 16px;
      display: flex;
      gap: 16px;
      .filterContainer {
        background: antiquewhite;
        padding: 4px 16px;
        border-radius: 16px;
        display: flex;
        gap: 6px;
        .icon {
          opacity: 0;
        }
      }
      .filterContainer:hover {
        cursor: pointer;
        .icon {
          color: grey;
          opacity: 1;
        }
      }
    }
  }
}
