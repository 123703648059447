@import url("https://fonts.googleapis.com/css2?family=David+Libre&display=swap");

@font-face {
  font-family: main-font-extra-light;
  src: url(../../assets/fonts/Assistant-ExtraLight.ttf);
}
@font-face {
  font-family: main-font-light;
  src: url(../../assets/fonts/Assistant-Light.ttf);
}
@font-face {
  font-family: main-font-regular;
  src: url(../../assets/fonts/Assistant-Regular.ttf);
}
@font-face {
  font-family: main-font-bold;
  src: url(../../assets/fonts/Assistant-Bold.ttf);
}
@font-face {
  font-family: main-font-semi-bold;
  src: url(../../assets/fonts/Assistant-SemiBold.ttf);
}
@font-face {
  font-family: main-font-extra-bold;
  src: url(../../assets/fonts/Assistant-ExtraBold.ttf);
}

@font-face {
  font-family: Narkisim;
  src: url(../../assets/fonts/NRKIS.ttf);
}

@font-face {
  font-family: FrankRuehl;
  src: url(../../assets/fonts/frank.ttf);
}

@font-face {
  font-family: David;
  src: url(../../assets/fonts/David.ttf);
}
