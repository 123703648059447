@import "../../style/setup/variables.scss";

.LoadingComponent {
  width: 100%;
  position: absolute;
  z-index: 6;
  margin: 0 auto;
  top: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .loading-modal {
    height: 310px;
    display: flex;
    align-items: center;
    justify-content: center;
    .BarLoader {
      width: 500px;
      height: 300px;
    }
    .container {
      transition: 0.4s ease-in-out;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .dots-wrapper {
        transition: 0.4s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .dot {
          transition: 0.4s ease-in-out;
          background-color: $clrMain;
          border-radius: 99px;
        }
      }
    }
  }
  .reason {
    font-family: main-font-regular;
    font-size: 2.3rem;
  }
}
