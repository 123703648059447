@import "../../style/setup/variables.scss";
@import "../../style/setup/mixins.scss";

.new-client-page {
  width: $mainPagesWidth;
  max-width: 100%;
  margin-top: 50px;
  position: relative;
  .client-form {
    display: flex;
    align-items: flex-start;
    margin-inline-end: 72px;
    .input-wrapper {
      margin-bottom: 67px;
    }
    .contacts-n-submit {
      height: 577px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      .contacts {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        .add-contact-btn {
          background-color: $clrMainDark;
          color: $clrWhite;
          font-size: 32px;
          font-family: main-font-regular;
          padding-bottom: 4px;
          margin-inline-start: 72px;
          width: 312px;
          height: 64px;
          border-radius: $borderRadius;
          transition: 0.3s;
        }
        .add-contact-btn:hover {
          @include darken-button($clrMainDark);
        }
        .contacts-list {
          height: 323px;
          overflow-y: scroll;
          padding-top: 24px;

          > * {
            margin-bottom: 24px;
          }
          .contact {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-inline-start: 72px;
            position: relative;
            background-color: $clrWhite;
            width: 312px;
            height: 64px;
            border-radius: $borderRadius;
            box-shadow: $cardShadow;
            padding-inline-start: 22px;
            font-size: 32px;
            color: $clrTextMediumGrey;
            .remove-btn {
              position: absolute;
              z-index: 4;
              top: -15px;
              right: -15px;
              color: $clrNegative;
              cursor: pointer;
            }
          }
        }
        .contacts-list::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
  .add-contact-form-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
  }
}
