.labelField {
  .Dropdown {
    .dropdownContainer {
      .optionsContainer {
        font-size: 22px;
      }
    }
    .placeholder {
      font-size: 22px;
    }
  }
}
