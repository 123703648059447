@import "../../style/setup/variables.scss";

.info-field {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  min-height: 50px;
  width: 100%;
  p {
    font-size: 25px;
    &.ltr {
      direction: ltr;
    }
  }
  .icn-wrapper {
    height: 50px;
    width: 50px;
    margin-inline-end: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $clrMainDark;
    font-size: 33px;
  }
  &.clickable {
    cursor: pointer;
    p {
      font-family: main-font-bold;
    }
    &.clickable:hover {
      p {
        text-decoration: underline;
      }
    }
  }
}
