@import "../../style/setup/variables.scss";
@import "../../style/setup/mixins.scss";

.new-meeting-page {
  max-width: $mainPagesWidth;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .header-section {
    margin-top: 28px;
    width: 100%;
    display: grid;
    grid-template-columns: 300px 1fr 300px;
    margin-bottom: 70px;
    height: 106px;
    .add-file-btn {
      width: 272px;
      height: 96px;
      margin-top: 10px;
      background-color: $clrMainDark;
      border-radius: $borderRadius;
      box-shadow: $cardShadow;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-inline-start: 18px;
      .icn-wrapper {
        font-size: 48px;
        color: $clrWhite;
        margin-inline-end: 20px;
      }
      p {
        font-size: 36px;
        color: $clrWhite;
        padding-bottom: 8px;
      }
    }
    .add-file-input {
      display: none;
    }
  }
  .file-drop {
    background-color: red;
    width: 100%;
    height: 100px;
  }
  .form-headers-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 33px;
    .header {
      position: relative;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid $clrMainDark;
      border-radius: $borderRadius;
      font-family: main-font-light;
      font-size: 36px;
      color: $clrMainDark;
      padding-bottom: 3px;
    }
    .header::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      top: 5px;
      left: 3px;
      border-radius: $borderRadius;
      border: 3px solid #a3a3a3d7;
      background-color: rgba(0, 0, 0, 0);
      filter: blur(5px);
      transition: 0.2s;
    }
  }
  .name {
    width: 312px;
  }
  .client {
    width: 312px;
  }
  .deadline {
    width: 128px;
  }
  .language {
    width: 176px;
  }
  .members {
    width: 272px;
  }
}
