.img-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  canvas {
    margin-bottom: 6px;
  }
  input {
    margin-bottom: 22px;
  }
  .btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;
  }
}
