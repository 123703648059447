@import "../../style/setup/variables.scss";

.LoginPage,
.SignUpPage,
.MfaPage,
.PasswordRecoveryPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  .newUser {
    margin-top: 16px;
    text-align: center;
  }
  .authContainer {
    width: 500px;
    min-height: 350px;
    background: white;
    padding: 40px;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: 400ms all;
    .bottomNote {
      &.logout {
        position: absolute;
        bottom: 40px;
      }
    }
    .headerTitle {
      font-size: 2rem;
      margin-bottom: 20px;
      align-self: center;
      text-align: center;
    }
    .headerIcon {
      font-size: 2.5rem;
      color: $newBlack;
      position: absolute;
      top: 40px;
      left: 40px;
      max-width: 150px;
      img {
        width: 100%;
      }
    }
    .note {
      margin-bottom: 30px;
      .noteTitle {
        font-size: 1.5rem;
        margin-bottom: 5px;
      }
      .noteBody {
        margin-bottom: 10px;
        .noteLine {
          margin-bottom: 3px;
          display: block;
          div {
            display: inline-block;
            &.phonenumber {
              font-weight: 700;
              font-size: 1.1rem;
            }
            &.ltr {
              direction: ltr;
            }
          }
        }
      }
    }
    .phoneLoginContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .styledForm {
      &.phoneLogin {
        .FormInput,
        .ButtonComponent {
          width: 200px;
        }
      }
      &.phoneLogin {
        .ButtonComponent {
          width: 150px;
        }
      }
      .ButtonComponent {
        margin-bottom: 10px;
      }
      .DigitInputComponent {
        margin-bottom: 20px;
      }
      .checkbox {
        margin-bottom: 16px;
        &.flex {
          display: flex;
        }
        .checkboxLabel {
          align-self: center;
          span,
          a {
            margin: 0 2px;
          }
        }
      }
    }
    .splitContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      .userPasswordContainer {
        position: relative;
        align-items: center;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .FormInput,
        .Dropdown,
        .Datepicker {
          width: 270px;
        }
        .loginButtonContainer {
          display: flex;
          align-items: center;
          gap: 20px;
        }
      }
      .or {
        width: fit-content;
        height: 1.9rem;
        position: absolute;
        bottom: 0;
        transform: translateY(25%);
        background: white;
        padding: 5px;
        font-size: 1.3rem;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        background: white;
      }
      .providersContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-top: 1px solid $newGray;
        padding-top: 40px;
      }
      .validationField {
        height: 21px;
        margin: 5px 0;
        color: $clrNegative;
      }
    }
    &.hidden {
      opacity: 0;
      transition: 400ms all;
    }
    .logout {
      text-align: left;
    }
  }
  .ReAuth {
    .reAuthBody {
      margin-bottom: 10px;
    }
  }
}
.sign-in-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
