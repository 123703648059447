@import "../../../style/setup/variables.scss";

.RangeSliderField {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .rc-slider-track {
    background-color: $clrMain;
  }
  .rc-slider-handle {
    border-color: $clrMain;
  }
  .values {
    display: flex;
    align-self: center;
    flex-direction: row;
    gap: 10px;
    .valueFieldContainer {
      width: 80px;
      display: flex;
      align-items: center;
      flex-direction: row;
      background-color: whitesmoke;
      height: 30px;
      gap: 2px;
      border: $grayBorder;
      border-radius: 6px;
      color: gray;
      margin-bottom: 8px;
      justify-content: center;
      overflow: hidden;
      .input {
        align-items: center;
        text-align: center;
        padding: 0px;
        color: $clrBlack;
        width: 100%;
        border: 0;
        font-family: "main-font-regular";
        font-size: 17px;
        height: 100%;
      }
      .currency {
        font-size: 1.1rem;
        color: $clrBlack;
        background-color: $lightGray;
        padding: 3px;
      }
      .valueField {
        font-size: 1.1rem;
        align-items: center;
        text-align: center;
      }
    }
  }
}
