.AuthProvidersButtons {
  .buttonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    .ProviderButton {
      width: 250px;
      height: 45px;
      cursor: pointer;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      padding: 10px;
      gap: 10px;
      box-shadow: 0px 2px 3px 1px #c2c2c2;
      border-radius: 3px;
      &.facebook {
        background-color: #4267b2;
        .icon {
          svg {
            width: 35px;
            :nth-child(1) {
              fill: #4267b2;
            }
          }
        }
        .label {
          color: white;
        }
      }
      .icon {
        width: 60px;
        text-align: center;
        svg {
          width: 35px;
        }
      }
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
