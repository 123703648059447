@import "../../style/setup/variables.scss";

.transcribers-page {
  width: $mainPagesWidth;
  max-width: 100%;
  margin: 0 25px;
  .transcribers-grid-container {
    margin-top: 60px;
    width: 100%;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, 384px);
    gap: 70px;
  }
}
