@import "../../../style/setup/variables.scss";

.DigitInputComponent {
  display: flex;
  gap: 5px;
  direction: ltr;
  .digit {
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    border: 0;
    border-bottom: 2px solid $newGray;
    text-align: center;
    &:focus,
    &:active {
      border-bottom: 2px solid $newBlack;
    }
  }
}
