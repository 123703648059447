.back-button {
  .back-btn {
    color: #1a73e8;
    display: flex;
    font-size: 16px;
    gap: 3px;
    margin-bottom: 16px;
    cursor: pointer;
    .back-icn-wrapper {
      font-size: 18px;
    }
  }
}
