@import "../../../style/setup/variables.scss";

.RangeValidation {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;

  .validationsContainer {
    width: 100%;
    height: 100%;

    .validationIcon {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.validationErrorsIcon {
        color: $statusRed;
      }
      &.validationWarningsIcon {
        color: $statusOrange;
      }
    }

    .validationIcon:hover + .validationsList {
      display: block;
    }
  }

  .validationsList {
    display: none;
    position: absolute;
    top: 20px;
    left: 20px;
    min-width: 300px;
    z-index: 100;
    padding: 10px;
    border: 1px solid $gray;
    background-color: white;
    .error,
    .warning {
      margin-bottom: 5px;
    }
    .error {
      color: $statusRed;
    }
    .warning {
      color: $statusOrange;
    }
    &:hover {
      display: block;
    }
  }

  &.ltr {
    right: 5px;
    left: unset;
    .validationsList {
      right: 20px;
      left: unset;
    }
  }
}
