@import "../../style/setup/variables.scss";
@import "../../style/setup/mixins.scss";

.main-title {
  &.user-profile {
    margin-top: 28px;
  }
}
.profileHeader {
  width: 100%;
  button {
    margin-bottom: 10px;
    margin-right: 77%;
    padding: 16px;
    border-radius: 10px;
    background: #1fd41ffc;
    color: white;
  }
}
.user-profile-page {
  width: $mainPagesWidth;
  margin: 1.5vh auto;
  display: flex;
  justify-content: center;
  .user-info-wrapper {
    width: 592px;
    margin-inline-end: 64px;
    position: relative;
    .user-info {
      width: 592px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: fixed;
      background-color: $clrWhite;
      border-radius: $borderRadius;
      box-shadow: $cardShadow;
      .info-field {
        padding-inline-start: 55px;
        padding-inline-end: 12px;
      }
      .img-editor-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 3;
        width: 100%;
        height: 100%;
        background-color: $clrWhite;
        border-radius: $borderRadius;
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .img-editor {
          margin-bottom: 80px;
        }
        .MuiCircularProgress-root {
          .MuiCircularProgress-svg {
            .MuiCircularProgress-circle {
              stroke: $clrMainDark;
            }
          }
        }
      }
      .user-img {
        align-self: center;
        width: 200px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $clrMainDark;
        border-radius: 999px;
        margin-bottom: 25px;
        position: relative;
        margin-top: 5%;
        .img-wrapper {
          @include aspect-ratio(1, 1);
          width: 93%;
          img {
            border-radius: 999px;
            width: 100%;
          }
        }
        .camera {
          position: absolute;
          font-size: 44px;
          color: $clrMainDark;
          width: 72px;
          height: 72px;
          cursor: pointer;
          left: -11px;
          bottom: -11px;
          background-color: $clrBrightBg;
          border-radius: 999px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .notifications {
  }
}
