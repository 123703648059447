@import "./../../../../style/setup/variables.scss";

.ColorPicker {
  position: relative;
  .colorPickerTrigger {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border: 2px solid $clrMainDark;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  .sketchPickerWrapper {
    z-index: 1;
    top: 15px;
    left: 15px;
    position: absolute;
  }

  &.disabled {
    .colorPickerTrigger {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
}
