.MultiTextInput {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  width: fit-content;

  .eachInputWrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 15px;
    svg {
      margin-inline-end: 15px;
      cursor: pointer;
    }
    input {
      border-radius: 5px;
      border: 1px solid #aaaaaa;
      padding: 7px 8px;
      width: 120px;
    }
  }
}
