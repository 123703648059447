@import "../../style/setup/variables.scss";

.new-meeting-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .meetingSpeakers {
    width: 400px !important;
    .option {
      &.disabled {
        opacity: 0.5;
      }
    }
  }
  .react-select__control {
    border: 0;
    border-radius: 0;
    min-height: 30px;
  }
  .fieldContainer {
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    gap: 5px;
    display: flex;
    .select-wrapper,
    .tree-select-input,
    .speakersDropdown {
      width: 40%;
      min-width: 400px;
    }

    &.meetingSpeakers {
      .fieldContent {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        .select-wrapper {
          width: 36%;
          min-width: auto;
        }
      }
    }
  }
  .fieldContainerDouble {
    width: 40%;
    min-width: 400px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    .fieldContainer .field {
      width: 100%;
    }
  }
  .field {
    width: 100%;
    padding: 5px;
    outline: none;
    border: none;
    border-bottom: 1px solid gray;
    font-family: main-font-light;
    font-size: 1.1rem;
  }
  .name {
    min-width: 400px;
    width: 40%;
  }
  .deadline {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    width: 128px;
    cursor: default;
    background-color: white;
    padding: 1px;
    p {
      font-family: main-font-light;
      color: $clrNewMeetingField;
      font-size: 1.1rem;
    }
    .time-unit-wrapper {
      cursor: pointer;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .time-unit-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        height: 25px;
        width: 25px;
      }
    }
  }
  .members-btn {
    width: 149px;
    background-color: $clrMainDark;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $clrWhite;
    cursor: pointer;
    max-height: 30px;
    p {
      font-family: main-font-regular;
      // font-size: 26px;
      color: $clrWhite;
      padding-bottom: 2px;
      margin-inline-end: 7px;
    }
    .members-count-controls {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      height: 30px;
      justify-content: space-between;
      align-items: center;
    }
    &.algo-none {
      visibility: hidden;
    }
  }
  .price {
    width: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: white;
    p,
    .price-input {
      font-family: main-font-light;
      color: $clrNewMeetingField;
      font-size: 1.1rem;
    }
    .price-input {
      width: 80%;
      background-color: transparent;
      text-align: center;
      border: none;
    }
    .price-input::-webkit-outer-spin-button,
    .price-input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .currency-symbol {
      margin-left: 20px;
    }
  }
  .actions {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
    .delete-btn {
      width: 128px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0);
      p {
        font-size: 1.2rem;
        font-family: main-font-regular;
        color: $clrNegative;
      }
    }
    .submit {
      width: 128px;
      background-color: $clrPositive;
      font-size: 1.3rem;
      font-family: main-font-regular;
      color: $clrWhite;
      padding-bottom: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.disabled {
        opacity: 0.5;
      }
    }
  }
  &.disabled {
    .row {
      .deadline {
        background-color: $clrMediumBg;
      }
      .price {
        background-color: $clrMediumBg;
      }
      .name {
        background-color: $clrMediumBg !important;
      }
      .hollow-shadow:hover {
        top: 5px;
        left: 3px;
        filter: blur(5px);
      }
    }
  }
}
