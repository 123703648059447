@import "../../../style/setup/variables.scss";

.Input,
.FormInput {
  position: relative;
  margin-bottom: 10px;
  max-width: 300px;
  &.mediumInput {
    width: 150px;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  input {
    width: 100%;
    height: 35px;
    font-family: main-font-regular;
    font-size: 1rem;
    color: $newBlack;
    border-radius: $borderRadius;
    border: 1px solid $newGray;
    padding: 8px;
    outline: none;
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &.ltr {
      direction: ltr;
      text-align: end;
      padding-inline-end: 22px;
    }
    &.alignRight {
      text-align: right;
    }
    &.alignEnd {
      text-align: end;
    }
    &.date {
      text-align-last: right;
    }
    & + .styledInput,
    & + .button {
      margin-top: 10px;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      padding-inline-start: 18px;
    }
    &:-internal-autofill-selected {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }
  &.alignRight {
    input {
      direction: ltr;
      text-align: right;
    }
  }
  &.error input {
    border: 1px solid $clrNegative;
  }
  .inputError {
    height: 17px;
    margin-bottom: 5px;
    color: $clrNegative;
    padding: 0 5px;
    font-size: 11px;
    position: absolute;
    background: white;
    bottom: -13px;
    left: 10px;
    padding: 0;
  }
}
