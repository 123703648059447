.PreviewPageWrapper {
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  &::-webkit-scrollbar {
    width: 0 !important;
  }

  .pageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    .previewPage {
      background: white;
      border-radius: 5px;
      margin-bottom: 30px;
      position: relative;
      .pageHeader {
        padding: 30px 30px 0 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .pageContent {
        display: flex;
        padding: 0 30px;
        flex-direction: column;
      }
      .pageFooter {
        padding: 0 30px 30px 30px;
        position: absolute;
        bottom: 0;
        width: 100%;
        .logo {
          p {
            font-size: 12pt;
          }
        }
      }
      .logo {
        width: 100%;
        display: flex;
        img {
          object-fit: contain;
        }
      }
      .paragraph {
        width: 100%;
        display: flex;
        margin-top: 15pt;

        .signatureStyle {
          width: 30%;
          margin-right: 1.5%;
          margin-left: 1.5%;
          padding-top: 20px;
          border-top: 1px solid;
          margin-bottom: 20px;
          margin-top: 20px;
        }

        &.tableOfSubjects {
          width: "fit-content";
          flex-direction: "column";
        }

        &.noMarginTop {
          margin-top: 0px;
        }
      }

      .textBoxA {
        border: 1px solid;
        padding: 30px;
        width: 80%;
      }
      .textBoxB {
        border-top: 1px solid;
        border-bottom: 1px solid;
        padding: 30px;
        width: 80%;
      }

      .annotationWrapper {
        display: flex;
        justify-content: center;
      }
    }
  }

  ul {
    list-style: none;
  }
}
