@import "../../style/setup/variables.scss";

.SpeakerNameEditor {
  transition: 200ms ease all;
  padding: 0;
  min-height: 25px;
  .speakerName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .newSpeakerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $gray;
    input {
      width: 200px;
      border: 0;
      font-family: "main-font-regular";
      font-size: 17px;
    }
    .AutocompleteInput {
      height: 25px;
      width: 120px;
      input {
        width: 100%;
      }
    }
    .iconContainer {
      display: flex;
      .icon {
        margin-right: 5px;
        cursor: pointer;
        &.approve {
          color: $clrPositive;
        }
        &.close {
          color: $gray;
        }
      }
    }
    &.error {
      border-bottom: 1px solid red;
    }
  }
  &.editMode {
    padding: 0 10px;
  }
}
