.JobAudit {
  min-width: 500px;
  min-height: 200px;
  &.MuiDialog-paperScrollPaper {
    max-height: 600px;
  }
  .MuiDialogContent-root::-webkit-scrollbar {
    display: none;
  }
  .MuiDialogContent-root {
    overflow: auto !important;
    padding-right: 0px;
    padding-left: 0px;
  }
  .MuiDialogActions-root {
    padding-bottom: 0px;
  }
}
