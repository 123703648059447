@import "../../style/setup/variables.scss";

.BarLoader {
  width: 200px;
  height: 70px;
  max-width: 450;
  max-height: 450;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  direction: ltr;
  .lines-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 18.4%;
    .line {
      width: 3%;
      border-radius: 99px;
      background-color: $clrMain;
      transition: 0.5s;
    }
  }
  .progressBar {
    height: 6px;
    width: 100%;
    border-radius: 3px;
    background: $clrNoData1;
    overflow: hidden;
    .bar {
      height: 100%;
      background: $clrMain;
    }
  }
}
