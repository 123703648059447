.MessageModal {
  overflow: visible !important;
  &.editModal {
    max-width: 750px;
    .editModalContainer {
      display: grid;
      gap: 30px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin-bottom: 30px;
      .field {
        width: 100%;
        .currencyContainer {
          display: flex;
          gap: 8px;
          .input {
            width: 150px;
          }
          .Dropdown {
            width: 120px;
            .dropdownContainer {
              .optionsContainer {
                overflow-y: hidden;
                height: fit-content;
                .option {
                  padding: 4px 8px;
                }
              }
            }
          }
        }
        .currency {
          width: 100%;
        }
        .invoiceSent {
          width: 100%;
        }
        &.name {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        &.jobType {
          grid-column-start: 3;
          grid-column-end: 5;
        }
        &.lang\.input {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        &.lang\.output {
          grid-column-start: 3;
          grid-column-end: 5;
        }
        &.deadline {
          grid-column-start: 3;
          grid-column-end: 5;
        }
        &.processProgressLastUpdate {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        &.small {
          width: 150px;
        }
      }
    }
  }
}
