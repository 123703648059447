.ValidationsConfigModal {
  &.ltr {
    direction: ltr;
  }
  .modalLoadarContainer {
    padding: 0px 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 700px;
  }
  .MuiDialog-paperScrollPaper {
    min-width: 850px;
    padding: 0px 24px;
    height: 950px;
  }
  .x_icon_container {
    .x_icon {
      padding: 16px;
      width: fit-content;
      cursor: pointer;
    }
  }
}
