// Flex-Helpers

.flex {
  display: flex;

  > *.full {
    flex: 1;
  }
  &.column {
    flex-direction: column;
  }
  &.column-reverse {
    flex-direction: column-reverse;
  }
  &.row-reverse {
    flex-direction: row-reverse;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-between {
    justify-content: space-between;
  }
  &.justify-around {
    justify-content: space-around;
  }
  &.justify-evenly {
    justify-content: space-evenly;
  }
  &.justify-flex-end {
    justify-content: flex-end;
  }
  &.align-center {
    align-items: center;
  }
  &.align-end {
    align-items: end;
  }
  &.align-flex-end {
    align-items: flex-end;
  }
  &.align-flex-start {
    align-items: flex-start;
  }
  &.align-start {
    align-items: start;
  }
}

.align-self-center {
  align-self: center;
}

.margin0auto {
  margin: 0 auto;
}

.nav-margin {
  margin-top: 47px;
}

.w100 {
  width: 100%;
}

.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: $topBarHeight;
  padding-top: 15px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 15px;
}

.main-title {
  font-family: main-font-extra-light;
  font-weight: lighter;
  font-size: $pageHeaderFontSize;
  // line-height: 70px;
  padding-bottom: 10px;
}

.square-ratio {
  @include aspect-ratio(1, 1);
}

.scroll-disable {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dot-container {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 999px;
    background-color: $clrPositive;
    margin-inline-end: 10px;
    transition: 0.2s;
  }
}

.loading-spinner {
  display: flex;
  align-items: center;
  margin: 0 auto;
  .MuiCircularProgress-root {
    color: $clrMainDark;
    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.dark-screen {
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.767);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
