@import "../../style/setup/variables.scss";

.MeetingsTable {
  .tableRow {
    &.v3 {
      .rowCell:first-of-type {
        border-inline-start: 2px solid $newBlue;
      }
    }
  }
  .rowCell {
    .status {
      color: white;
      padding: 1px 10px 1px 10px;
      border-radius: 16px;
      display: flex;
      .finished {
        background-color: $statusGreen;
      }
      .proofing {
        background-color: $statusOrange;
      }
      .transcribing {
        background-color: $statusRed;
      }
    }
    .representativeContainer {
      display: flex;
      gap: 4px;
      .representativePrice {
        display: flex;
        gap: 2px;
      }
    }
    .invoiceSent {
      display: flex;
      .invoiceSent {
        border-radius: $borderRadius;
        padding: 4px 12px;
        &.open {
          background-color: $peach;
        }
        &.check {
          background-color: $sand;
        }
        &.sent {
          background-color: $turquoise;
        }
      }
    }
    .memberCell,
    .previewFormatCell {
      display: flex;
      flex-flow: column;
      .prooferDiv,
      .langDiv {
        color: gray;
      }
      .prooferDiv,
      .transcriberDiv {
        display: flex;
        gap: 4px;
        .priceDiv {
          display: flex;
          gap: 2px;
        }
      }
    }
    .fileName {
      div {
        text-align: start;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .jobName {
        font-family: main-font-semi-bold;
      }
      .clientName {
        color: $newGray;
      }
    }
    .deadlineCell {
      .deadlineDiv {
        color: gray;
      }
      .clientDeadlineDiv {
        color: $newBlack;
        font-weight: bold;
      }
    }
    .jobCell {
      text-align: center;
      .jobStatusDiv {
        width: fit-content;
        padding: 2px 8px;
        border-radius: $borderRadius;
        &.at_work {
          background-color: $sand;
        }
        &.open {
          background-color: $peach;
        }
        &.reviewing {
          background-color: $turquoise;
        }
        &.pending {
          background-color: #99cbe7;
        }
        &.ready {
          background-color: $ligthGreen;
        }
      }
    }
    .actionButton {
      border-radius: 3px;
      padding: 5px 15px;
      cursor: pointer;
      &.assign,
      &.request {
        background: black;
        color: white;
      }
      &.cancel {
        background: red;
        color: white;
      }
      &.release {
        background: red;
        color: white;
      }
      &.take {
        background: black;
        color: white;
      }
      &.approve {
        background: $clrPositive;
        color: white;
      }
      &.refuse {
        background: none;
        color: red;
        &:hover {
          color: black;
        }
      }
      &.export {
        margin-left: 10px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
