@import "../../style/setup/variables.scss";

.Editor.main-container {
  height: calc(100vh - 60px);
  overflow: hidden;
  font-family: main-font-light;
  padding-bottom: 0;
  &.protocol {
    display: grid;
    grid-template-areas:
      "header   header"
      "ranges   speakers"
      "ranges   player"
      "ranges   .";
    grid-template-columns: auto 600px;
  }
  @media (max-width: 1600px) {
    grid-template-columns: auto 500px;
  }
  align-content: flex-start;
  gap: 10px;
  .headerSection {
    width: 100%;
    background-color: $clrMainDark;

    grid-area: header;
    display: flex;
    justify-content: space-between;
  }
  .playerSection {
    display: flex;
    align-self: start;
    position: sticky;
    top: $stickyTop;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    grid-area: player;
  }
}

.JobInfoPanel {
  height: 50px;
  background-color: $clrMainDark;
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 10px;
  cursor: default;
  .infoPanel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    gap: 50px;
    &.infoPanel {
      width: 100%;
      align-items: flex-end;
      color: white;
    }
    .jobInfoItem {
      display: flex;
      gap: 5px;
      align-items: center;
      font-size: 1.1rem;
      a {
        color: white;
      }
    }
    .jobName {
      flex-grow: 1;
      width: 450px;
      white-space: nowrap;
      font-size: 1.7rem;
      line-height: 1.7rem;
      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.JobActionPanel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  background-color: $clrMainDark;
  color: white;
  .approvalPanel {
    gap: 10px;
    justify-content: space-between;
    background-color: $clrMainDark;
    color: white;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 35px;
    gap: 50px;
    &.rtl {
      box-shadow: 1px 0px 0px $darkGray, 2px 0px 0px black;
    }
    &.ltr {
      box-shadow: -1px 0px 0px $darkGray, -2px 0px 0px black;
    }
    .jobApproval,
    .jobRestore,
    .startLiveInterview,
    .endLiveInterview {
      cursor: pointer;
      &:hover {
        color: #737f8f;
      }
    }
  }
  .action {
    width: 50px;
    height: 45px;
    position: relative;
    font-size: 20px;
    transition: 200ms ease all;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .actionLabel {
      width: 150px;
      height: 0;
      text-transform: uppercase;
      font-size: 0.7rem;
      font-family: main-font-medium;

      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;

      transition: 200ms ease all;
    }
    .actionIcon {
      opacity: 1;
      transition: 400ms ease all;
    }

    &.timestampsEnterMethod {
      .actionIcon {
        transform: rotate(90deg);
      }
    }
    &:hover {
      cursor: pointer;
      color: rgba(255, 255, 255, 0.8);
      .actionLabel {
        opacity: 1;
        height: 16px;
        transition: 300ms ease all;
      }
      .actionIcon {
        opacity: 0.4;
        transition: 300ms ease all;
      }
    }
    &.disabled {
      cursor: default;
      .actionIcon {
        opacity: 0.4;
      }
    }
  }
}
.JobSpeakersPanel {
  width: 100%;
  background-color: $clrMediumBg;
  box-shadow: 0px 10px 0px $clrMediumBg;
  z-index: 10;
  grid-area: speakers;
  .speakersContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 0 8px;
    background-color: white;
    border-bottom: 2px solid $clrMainDark;
    padding: 8px 15px;
  }
}

.ProtocolEditor,
.SubtitlesEditor {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-area: ranges;
  #editorContainer {
    height: calc(100vh - 135px);
    padding-bottom: 120px;
  }
  &.SubtitlesEditor {
    #editorContainer {
      width: 60%;
    }
  }
  .textRange {
    margin-bottom: 10px;
    .rangeTimes {
      &.subtitles {
        min-width: 160px;
        .TimerPicker {
          justify-content: space-around;
        }
      }
      &.protocol {
        min-width: 190px;
      }
      .speakerBlockContainer {
        width: 190px !important;
        position: sticky;
        top: 0px !important;
      }
    }
  }
}

.EditorOld {
  // old
  width: 95vw;
  max-width: 100%;
  min-width: 1000px;
  margin: 0 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 230px;
  font-family: main-font-light;
  font-weight: lighter;
  .editorHeader {
    display: flex;
    justify-content: space-between;
    .roomName {
      font-size: 3.5rem;
      margin-bottom: 20px;
    }
    .transcriberAndProoferName {
      align-self: center;
      font-size: 2rem;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      a {
        text-decoration: none;
      }
    }
  }
  .readOnly {
    font-size: 1.5rem;
    color: #555555;
    font-weight: 700;
  }
  .detailsContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: $clrMainDark;
    font-size: 1.4rem;
    color: $clrWhite;
    position: relative;
    span {
      font-family: main-font-light;
      font-weight: lighter;
    }
    .roomMetadata {
      display: flex;
      gap: 50px;
      .detail-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .icn-wrapper {
          color: $clrWhite;
          margin-inline-end: 8px;
        }
      }
    }
    .roomActions {
      display: flex;
      gap: 30px;
      .actionButton {
        display: block;
        align-items: center;
        gap: 5px;
        font-size: 1.4rem;
        background: none;
        color: $clrWhite;
        &:hover {
          color: $clrMain;
        }
        &:focus {
          color: $clrMain;
        }
      }
    }
  }

  .speakersContainer,
  .subtitlesActionsContainer {
    flex-flow: wrap;
    width: 100%;
    background-color: $clrWhite;
    margin-top: 1.25vh;
    padding: 10px;
    box-shadow: 0px 2px 0px $clrMainDark;
    display: flex;
    .speaker {
      margin-left: 15px;
      font-size: 18px;
      cursor: pointer;
      &:hover {
        opacity: 0.6;
      }
    }
  }

  .subtitlesActionsContainer {
    flex-direction: row;
    justify-content: flex-end;
    gap: 30px;
    .action {
      display: flex;
      flex-direction: row;
      flex-flow: row-reverse;
      gap: 5px;
      cursor: pointer;
      &.timestampsEnterMethod {
        .icon {
          margin: 0 5px;
          transform: rotate(90deg);
        }
      }
      &.disabled {
        opacity: 0.3;
        &:hover {
          cursor: default;
          opacity: 0.3;
        }
      }
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .multiRanges {
    .editorRanges {
      flex: 3;
    }
    .editorPlayer {
      flex: 2;
    }
  }
  .editorSection {
    width: 100%;
    display: flex;
    gap: 20px;
    position: relative;
    .editorRanges {
      width: 100%;
      .textContainer {
        width: 100%;
      }
    }
    &.splitView {
      .editorRanges {
        width: 70%;
      }
      .editorPlayer {
        width: 30%;
        height: 500px;
        display: flex;
        position: sticky;
        top: 40px;
        flex-direction: column;
        align-items: center;
        .VideoPlayerComponent {
          width: 100%;
          margin-bottom: 20px;
        }
        .videoPlayerContainer {
          width: 100%;
          height: auto;
          aspect-ratio: 1.6;
        }
      }
    }
  }

  &.ltr {
    .speakersContainer {
      direction: ltr;
    }

    .editorSection {
      direction: ltr;
    }
  }

  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - #{$topBarHeight});
  &.waveform {
    height: calc(100vh - #{$topBarHeight} - #{$waveformHeight});
  }
}

.jobSplitModal {
  .bodyContainer {
    .countSelect {
      display: flex;
      align-items: baseline;
      gap: 10px;
      .Input {
        width: 100px;
      }
    }
  }
}
