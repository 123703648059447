.AutocompleteInput {
  position: relative;
  height: 20px;
  input {
    position: absolute;
    right: 0;
    top: 0;
    &.freeTextInput {
      opacity: 0.6;
    }
  }
}
