//Colors

$clrMain: #ffb400;
$clrMainDark: #323232;
$clrBrightBg: #f6f6f6;
$clrMediumBg: #ebebeb;
$clrWhite: #ffffff;
$clrWhite2: #f2f4f6;
$clrBlueWhite: #e8ecf1;
$clrBlack: #000000;
$clrNegative: #ce2929;
$clrNeutral: #006185;
$clrPositive: #3bb03b;
$clrNavLink: #e5e5e5;
$clrInputPlaceholder: #8b8b8b;
$clrLigthBlue: #adccf1f3;
$clrOverlap: #f58282;

$clrNoData1: #aaaaaa;
$clrNoData2: #707070;
$clrNewMeetingField: #626161;
$clrDarkenedSoft: #616161;
$clrTextMediumGrey: #464646;

$gray: #a4a4a4;
$lightGray: #eaeaf1;
$lightGray2: #b2bbc9;
$newGray: #8593aa;
$newBlack: #222936;
$newBlue: #3c5069;
$darkGray: #5b5b5b;
$linkColor: #0665cb;
$disabledColor: #cbcbcb;
$whitesmoke: whitesmoke;
$peach: #e7a199;
$sand: #e7d899;
$turquoise: #53baf3;
$ligthGreen: #33c31a70;
$orangeTableHeader: #ffb400;

// status color

$statusGreen: #2dce10;
$statusOrange: orange;
$statusRed: #ce1010;
$statusBlue: #1023ce;

//General look

$icnSize: 20px;
$mainPagesWidth: 1296px;
$topBarHeight: 60px;
$stickyTop: 60px;
$waveformHeight: calc(160px + 20px);
$borderRadius: 6px;
$normalPadding: 8px;
$cardShadow: 5px 4px 8px #a3a3a366;
$cardShadowHover: 3px 3px 1px #6e6e6e91;
$centeredShadow: 0px 0px 4px #a3a3a3bf;
$pageHeaderFontSize: 2.5rem;
$grayBorder: solid #a2a0a0 0.3px;

//Breakpoints

$mobile-breakpoint: 420px;
$narrow-breakpoint: 720px;
$wide-breakpoint: 1366px;
