@import "../../style/setup/variables.scss";

.page-header {
  width: $mainPagesWidth;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 25px;
  .header-grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: 240px 1fr 240px;
    grid-template-rows: 23px 90px;
    justify-items: center;
    margin-bottom: 35px;
    .back-wrapper {
      grid-column: 2;
      grid-row: 1;
      height: 23px;
      .back-btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        .back-icn-wrapper {
          font-size: 26px;
          margin-inline-end: 3px;
          padding-top: 6px;
        }
        h3 {
          font-family: main-font-regular;
          font-size: 26px;
          font-weight: normal;
        }
      }
    }
    .sorting {
      align-self: center;
      justify-self: flex-start;
      grid-column: 1;
      grid-row: 2;
    }
    .main-title {
      align-self: center;
      overflow: hidden;
      grid-column: 2;
      grid-row: 2;
    }
    .search-bar {
      justify-self: flex-end;
      align-self: center;
      grid-column: 3;
      grid-row: 2;
    }
  }
}
