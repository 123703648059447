@import "../../style/setup/variables.scss";

.notification-preview {
  width: 640px;
  background-color: $clrWhite;
  box-shadow: $cardShadow;
  border-radius: $borderRadius;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 26px;
  .status-bar {
    width: 100%;
    height: 16px;
    background-color: $clrPositive;
    &.approved {
      background-color: $clrMain;
    }
  }
  .main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 57px;
    padding: 8px 20px;
    .content {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      flex-basis: 85%;
      .notification-body-text {
        font-size: 1.1rem;
        line-height: 200%;
        span {
          color: $clrMain;
        }
      }
    }
    .time {
      p {
        color: $clrMainDark;
        direction: ltr;
      }
    }
  }
  .is-read {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 15%;
    button {
      background-color: $clrMainDark;
    }
  }
}
