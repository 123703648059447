@import "../../style/setup/variables.scss";

.SubtitlesTranslationEditor {
  width: 100%;
  display: flex;
  flex-direction: column;
  .titles {
    display: flex;
    justify-content: space-between;
    .title {
      width: 50%;
      gap: 6px;
      display: flex;
      justify-content: flex-end;
      .lang {
        font-weight: 500;
        font-size: 1.6rem;
      }
      .rangesType {
        color: $gray;
        font-size: 1.2rem;
        align-self: center;
      }
    }
  }

  .subtitlesTranlationRanges::-webkit-scrollbar {
    display: none;
  }

  #editorContainer {
    padding-bottom: 200px;
  }
  .lazyload-wrapper {
    .RangeSubtitlesTranslate {
      display: flex;
      gap: 8px;
      .textRange {
        width: 100%;
        margin-bottom: 10px;
        gap: 0px;
        position: relative;
        .iconsContainer {
          position: absolute;

          display: flex;
          flex-direction: column;
          top: 8px;
          gap: 8px;
          .restoreIcon:hover {
            color: $clrBlack;
            cursor: pointer;
          }
          .translateIcon:hover {
            color: $linkColor;
            cursor: pointer;
          }
          .RangeValidation {
            position: relative;
            top: 0px;
            left: 0px;
          }
        }
        .rangeText {
          .disabled {
            height: 100%;
          }
          .textContainer {
            height: 100%;
            .textRangeField:focus {
              box-shadow: 0px 0px 0px white;
            }
          }
        }
        &.ltr {
          .iconsContainer {
            right: 8px;
            .restoreIcon {
              transform: rotateY(180deg);
            }
          }
          .rangeText {
            .textContainer {
              .textRangeField {
                padding-right: 60px;
              }
            }
          }
        }
        &.rtl {
          .iconsContainer {
            left: 8px;
          }
          .rangeText {
            .textContainer {
              .textRangeField {
                padding-left: 60px;
              }
            }
          }
        }
      }
    }
  }
}
