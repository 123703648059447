@import "../../style/setup/variables.scss";

.new-transcriber-page {
  margin-top: 50px;
  width: 1257px;
  display: flex;
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  form {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    > * {
      margin-inline-end: 64px;
    }
    .submit-btn {
      position: absolute;
      bottom: 0;
      transform: translateY(100%);
    }
  }
  .input-wrapper {
    margin-bottom: 64px;
  }
  .bank-details {
    width: 482px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
