.AdminPage {
  padding: 20px 100px;
  .GenericTable {
    overflow: visible;
    .MuiTableContainer-root {
      overflow-x: visible;
    }
    .ffUsers {
      min-width: 200px;
    }
  }
  .featureFlags {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-bottom: 10px;
    }
    .featuresContainer {
      width: 70%;
      .ffContainer {
        display: flex;
        align-items: center;
      }
    }
  }
}
