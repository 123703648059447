@import "../../style/setup/variables.scss";
@import "../../style/setup/mixins.scss";

.transcriber-page {
  width: $mainPagesWidth;
  display: flex;
  justify-content: flex-start;
  .details {
    background-color: $clrWhite;
    border-radius: $borderRadius;
    box-shadow: $cardShadow;
    width: 848px;
    height: 544px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 9fr 5fr;
    grid-template-rows: repeat(5, 20%);
    padding: 55px;
    padding-inline-end: 32px;
    margin-inline-end: 64px;
    .upload-contract-wrapper {
      height: 50px;
      display: flex;
      align-items: center;
      .icn-wrapper {
        height: 50px;
        width: 50px;
        margin-inline-end: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $clrMainDark;
        font-size: 33px;
      }
      .text {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 32px;
        color: $clrMain;
        height: 100%;
        &.disabled {
          color: $clrMainDark;
        }
        p {
          font-size: 24px;
          padding-bottom: 3px;
          margin-inline-end: 10px;
        }
        .mini-icn {
          padding-top: 3px;
          height: 100%;
          font-size: 20px;
        }
      }
    }
  }
  .entity-edit {
    height: 544px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .user-img-wrapper {
      width: 262px;
      height: 262px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.2s ease-in-out;
      background-color: $clrMainDark;
      border-radius: 999px;
      .user-img {
        width: 94%;
        height: 94%;
        @include aspect-ratio(1, 1);
      }
      img {
        border-radius: 999px;
        width: 100%;
      }
    }
    .entity-btns {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .approve {
        width: 350px;
        height: 168px;
        background-color: $clrPositive;
        margin-bottom: 24px;
        box-shadow: $cardShadow;
        border-radius: $borderRadius;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        p {
          color: $clrWhite;
          font-size: 36px;
        }
        &.disabled {
          background-color: $clrMainDark;
          cursor: default;
        }
      }
      .remove-entity-btn {
        align-self: flex-end;
      }
    }
  }
}
.userRoleDiv {
  display: flex;
  .MuiInputLabel-formControl {
    right: 0;
  }
}
.submit {
  height: 57px;
  // align-self: self-end;
  background-color: $clrPositive;
  font-size: 1.3rem;
  font-family: main-font-regular;
  color: $clrWhite;
  padding-bottom: 3px;
  &.disabled {
    opacity: 0.5;
  }
  &.cancel {
    background-color: $clrNegative;
    padding-right: 24px;
    padding-left: 24px;
    width: 150px;
    .loading-spinner {
      display: block;
    }
  }
}
