@import "../../style/setup/variables.scss";

.inputContainer {
  width: 150px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  .editPen {
    display: flex;
    align-items: center;
    transition: all 0.3s;
    opacity: 0;
  }
  .input {
    align-self: center;
  }
}
.inputContainer:hover {
  .editPen {
    opacity: 1;
  }
}
.EditableText {
  width: 150px;
  transition: 200ms ease all;
  padding: 0;
  min-height: 25px;
  .speakerName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .newInputContainer {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $gray;
    input {
      padding: 0px;
      width: 100%;
      border: 0;
      font-family: "main-font-regular";
      font-size: 17px;
    }
    .AutocompleteInput {
      height: 25px;
      width: 120px;
      input {
        width: 100%;
      }
    }
    .iconContainer {
      display: flex;
      .icon {
        margin-right: 5px;
        cursor: pointer;
        &.approve {
          color: $clrPositive;
        }
        &.close {
          color: $gray;
        }
      }
    }
    &.error {
      border-bottom: 1px solid red;
    }
  }
  .inputContainer {
    &.ellipsis {
      width: 150px;
      .input {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.deadline {
  padding-left: 0px;
  padding-right: 0px;
  .EditableText {
    width: 100%;
    .inputContainer {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}

.EditableText {
  &.clientPage {
    width: 300px;
    .inputContainer {
      width: 300px;
    }
    .newInputContainer {
      width: 300px;
      input {
        background-color: #ebebeb;
        font-size: 1.1rem;
      }
    }
  }
}
