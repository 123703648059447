.toggle-btn {
  width: 50px;
  height: 20px;
  border-radius: 999px;
  background-color: $clrMain;
  box-shadow: $cardShadow;
  cursor: pointer;
  .inner-circle {
    width: 20px;
    height: 20px;
    background-color: $clrWhite;
    border-radius: 999px;
    transition: 0.2s ease-in-out;
    &.switched {
      margin-inline-start: 30px;
    }
  }
}
