@import "../../style/setup/mixins.scss";

.sign-in-btn {
  // width: 168px;
  height: 56px;
  border-radius: 20px;
  background-color: $clrPositive;
  font-family: main-font-regular;
  color: $clrWhite;
  font-size: 36px;
  padding-bottom: 6px;
  padding-left: 30px;
  padding-right: 30px;
  transition: 0.3s;
  &.secondary {
    background-color: rgba(255, 255, 255, 0);
    color: $clrBlack;
    padding-bottom: 6px;
  }
}

.sign-in-btn:hover {
  @include darken-button($clrPositive);
  &.secondary {
    background-color: rgba(255, 0, 0, 0);
    text-decoration: underline;
  }
}

.meeting-preview-btn {
  transition: 0.2s;
  height: 41px;
  font-family: main-font-regular;
  font-size: 26px;
  padding-bottom: 4px;
  border-radius: $borderRadius;
}

button {
  &.neutral {
    background-color: $clrMainDark;
    color: $clrWhite;
  }
}
button {
  &.positive {
    background-color: $clrPositive;
    color: $clrWhite;
  }
}
button {
  &.negative {
    background-color: $clrNegative;
    color: $clrWhite;
  }
}
button {
  &.negative-hollow {
    background-color: rgba(255, 255, 255, 0);
    color: $clrNegative;
  }
}
button {
  &.positive-hollow {
    background-color: rgba(255, 255, 255, 0);
    color: $clrPositive;
  }
}
button {
  &.neutral-hollow {
    background-color: rgba(255, 255, 255, 0);
    color: $clrMainDark;
    transition: 0s;
  }
}

button:hover {
  &.neutral {
    @include darken-button($clrMainDark);
  }
}
button:hover {
  &.positive {
    @include darken-button($clrPositive);
  }
}
button:hover {
  &.negative {
    @include darken-button($clrNegative);
  }
}
button:hover {
  &.positive-hollow {
    text-decoration: underline;
    text-decoration-color: $clrPositive;
  }
}
button:hover {
  &.negative-hollow {
    text-decoration: underline;
    text-decoration-color: $clrNegative;
  }
}
button:hover {
  &.neutral-hollow {
    text-decoration: underline;
    text-decoration-color: $clrMainDark;
  }
}

.notification-btn {
  transition: 0.2s;
  height: 5vh;
  background-color: $clrPositive;
  font-family: main-font-regular;
  color: $clrWhite;
  font-size: 1rem;
  border-radius: $borderRadius;
}
.notification-btn:hover {
  @include darken-button($clrPositive);
}

.hollow-shadow {
  position: absolute;
  top: 5px;
  left: 3px;
  filter: blur(5px);
  z-index: 0;
  border-radius: $borderRadius;
  border: 3px solid #a3a3a3d7;
  content: "";
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  transition: 0.2s;
}

.hollow-shadow:hover {
  filter: blur(2px);
  top: 2px;
  left: 1px;
}
