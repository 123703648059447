@import "../../style/setup/variables.scss";
.textRange {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
  min-height: 65px;
  .rangeTimes {
    min-height: inherit;
    &.protocol {
      min-width: 190px;
      .speakerBlockContainer {
        width: 180px;
        min-height: inherit;
        background-color: white;
        padding: 8px;
        position: sticky;
        top: -5px;
        .speakerNameContainer {
          .speakerName {
            &.unidentifiedSpeaker {
              color: red !important;
              text-align: center;
            }
            display: block;
          }
        }
        .speakerTime {
          zoom: 0.9;
          .TimerPicker {
            justify-content: flex-end;
            gap: 3px;
          }
          .startTime,
          .endTime {
            &.allowTimeEdit:hover {
              cursor: pointer;
            }
          }
        }
      }
      &.overlapping {
        .speakerBlockContainer {
          background-color: $clrOverlap;
          .speakerName {
            color: white !important;
          }
        }
      }
    }
    display: flex;
    flex-direction: column;
    span {
      font-family: "main-font-regular";
      font-size: 19px;
    }
  }
  .rangeText {
    min-height: inherit;
    position: relative;
    flex-grow: 1;
    .textContainer {
      min-height: inherit;
      position: relative;
      .textRangeField {
        width: 100%;
        height: 100%;
        padding: 8px;
        position: absolute;
        font-family: "main-font-regular";
        font-size: 22px;
        letter-spacing: 1.2px;
        border: 0;
        resize: none;
        box-sizing: border-box;
        unicode-bidi: embed;
        padding-inline-end: 20px;
        &:focus {
          outline: 0;
          box-shadow: 2px 0px 0px $gray;
        }
      }
      .dummyRange {
        unicode-bidi: embed;
        width: 100%;
        padding: 8px;
        font-family: "main-font-regular";
        font-size: 22px;
        letter-spacing: 1.2px;
        word-wrap: break-word;
        white-space: pre-wrap;
        padding-inline-end: 20px;
      }

      &.disabled {
        background: #f7f7f7;
      }
    }
  }
  .speakerListContainer {
    position: relative;
    width: 100%;
  }
  .speakerList {
    width: 100%;
    background: white;
    padding: 0px;
    z-index: 1;
    max-height: 0;
    overflow: hidden;
    transition: all 500ms ease;

    &.open {
      transition: all 500ms ease;
      max-height: 800px;
      overflow-y: scroll;
    }

    .addSpeaker {
      padding: 10px 0;
      cursor: pointer;
      span {
        font-size: 17px;
        margin-left: 4px;
      }
    }
    .speaker {
      cursor: pointer;
      &:hover {
        margin-right: 5px;
      }
    }
  }
  .speakerListContainer {
    .addNewSpeaker {
      cursor: pointer;
    }
  }
  &.ltr {
    .textRangeField,
    .dummyRange {
      direction: ltr;
    }
  }
  &.passed {
    opacity: 0.8;
    .TimerPicker,
    .textRangeField,
    input {
      background-color: #fafafa;
      color: #838383;
    }
  }
  &.warning {
    .textContainer {
      border-inline-start: 2px solid $statusOrange;
    }
  }
  &.error {
    .textContainer {
      border-inline-start: 2px solid $statusRed;
    }
  }
  &.overlapping {
    textarea,
    input,
    .textRangeField,
    .TimerPicker {
      background: #e7b1b1;
    }
  }
}
