@import "../../../style/setup/variables.scss";

.SelectList {
  width: 300px;
  position: relative;
  margin-bottom: 10px;
  .listContainer {
    width: 100%;
    height: 300px;
    border-radius: $borderRadius;
    border: 1px solid $newGray;
    z-index: 10;
    background-color: white;
    top: 28px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .search {
      width: 100%;
      padding: 8px;
      input {
        width: 100%;
        height: 35px;
        padding: 10px;
        background-color: #f2f4f6;
        border: none;
        border-radius: 100px;
      }
    }
    .optionsContainer {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 100%;
      .loading-spinner {
        justify-content: center;
        align-content: center;
        height: 100%;
      }
      .option {
        cursor: pointer;
        padding: 4px 16px;
        display: flex;
        align-items: center;
        gap: 5px;
        &.selected {
          background-color: #d5d5d5;
        }
        &.checkbox {
          cursor: default;
          input,
          label {
            cursor: pointer;
          }
          label {
            width: 100%;
            height: 100%;
          }
        }
        &:hover {
          background-color: #e3e3e3;
        }
      }
    }
  }
}

.ltr {
  .SelectList {
  }
}
