@import "./../../../../style/setup/variables.scss";

.ImageUpload {
  display: flex;
  gap: 10px;

  label {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input {
    display: none;
    height: 0;
    width: 0;
    opacity: 0;
    visibility: hidden;
  }

  .previewImageWrapper {
    position: relative;
    width: 95px;
    height: 35px;
    cursor: pointer;
    .delete {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
      width: 20px;
      height: 20px;
      opacity: 0;
      transform: scale(1);
      transition: all 0.5s;
      color: $clrNegative;
    }

    img {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
      border: none;
      width: 80px;
      height: 40px;
      object-fit: contain;
      margin-inline-start: 10px;
      transform: scale(1);
      transition: all 0.5s;
    }
    &:hover {
      .delete {
        transform: scale(1.1);
        opacity: 1;
      }
      img {
        opacity: 0.2;
        transform: scale(0.9);
      }
    }
  }

  &.disabled {
    opacity: 0.5;
    .previewImageWrapper {
      cursor: not-allowed;
      &:hover {
        .delete {
          transform: scale(1);
          opacity: 0;
        }
        img {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}
