@import "../../../style/setup/variables.scss";

.ExportModalV3 {
  .MuiDialog-paperWidthSm {
    max-width: fit-content;
    background: $clrBrightBg;
  }

  .MuiDialogContent-root {
    .main-container {
      margin-top: 0;
      padding: 0;
    }
  }

  .titleWrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    .closeBtn {
      cursor: pointer;
    }
  }
}
