@import "../../style/setup/variables.scss";

.TimerPicker {
  direction: ltr;
  text-align: right;
  background: white;
  width: 100%;
  margin-bottom: 5px;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  .timerContainer {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    .TimerBlock {
      width: 22px;
      outline: none;
      border: 0;
      padding: 0 1px;
      font-size: 16px;
      caret-color: transparent;
      background: inherit;

      &.milliseconds {
        width: 30px;
      }

      &:focus {
        background: #abc7ee !important;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &::selection {
        background: transparent;
      }

      &[type="number"] {
        -moz-appearance: textfield;
      }
    }
    .separator {
      color: #4f4f4f;
    }

    &.invalid {
      color: red;
      input {
        color: red;
      }
    }
    .readOnlyTime {
      font-size: 18px;
    }
  }
  &.ltr {
    flex-direction: row-reverse;
  }
  &.disabled {
    background-color: #f7f7f7;
  }

  .timingButton {
    color: $clrNoData1;
    cursor: pointer;
    margin: 0 2px;
    &:hover {
      color: #000;
    }
  }
  input[type="time"] {
    border: none;
    font-size: 16px;
    font-family: Arial;
    width: 115px;
  }
  input[type="time"]::-webkit-calendar-picker-indicator {
    display: none;
  }
  input[type="time"]::-webkit-datetime-edit-text {
    padding: 2px;
  }

  &.overlapping {
    border-inline-start: 2px solid $statusRed;
    background: $clrOverlap;
    .timingButton {
      color: $newBlack;
    }
  }
}
