@import "../../style/setup/variables.scss";
@import "../../style/setup/mixins.scss";

.top-bar {
  background-color: $clrMainDark;
  width: 100%;
  height: $topBarHeight;
  position: fixed;
  z-index: 99;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 26px;
  box-shadow: 0px 10px 0px #ebebeb;
  .logo-wrapper {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    direction: ltr;
    .logo {
      width: 150px;
      cursor: pointer;
    }
    .environment {
      line-height: 13px;
      font-size: 16px;
      margin-left: 7px;
      color: #e74545;
    }
  }
  .navigation-container {
    display: flex;
    align-items: center;
    height: 100%;
    .nav-btn {
      margin-inline-end: 30px;
    }
    .userContainer {
      display: flex;
      gap: 20px;
      .username {
        color: white;
        font-size: 30px;
        font-family: main-font-regular;
        font-weight: normal;
      }
      .user-img {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $clrMain;
        border-radius: 100%;
        position: relative;
        .notifications-count {
          padding: 0 3px;
          position: absolute;
          bottom: -10px;
          background-color: $clrMain;
          border-radius: 999px;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 3;
          p {
            font-family: main-font-bold;
            color: black;
            font-size: 12px;
            padding-bottom: 1px;
          }
        }
        .img-wrapper {
          cursor: pointer;
          @include aspect-ratio(1, 1);
          width: 90%;
          img {
            border-radius: 99px;
            width: 100%;
          }
        }
      }
    }
  }
  &.hidden {
    display: none;
  }
}

@media screen and (max-width: 1145px) {
  .top-bar {
    .navigation-container {
      h3 {
        font-size: 4.2vw;
        line-height: 35px;
      }
    }
  }
}
