@import "../../style/setup/variables.scss";

.add-debt-form-wrapper {
  background-color: $clrBrightBg;
  padding-top: 60px;
  .add-debt-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .input-wrapper {
      margin-bottom: 64px;
    }
    button {
      margin-top: 7px;
      width: fit-content;
      padding: 25px;
      height: 80px;
      line-height: 0;
    }
  }
}
