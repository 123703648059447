@import "./../../style/setup/variables.scss";

$mainBgColor: $clrWhite2;
$mainBorderColor: $lightGray2;

.ValidationsConfigForm {
  padding: 0px 25px;
  display: flex;
  flex-direction: column;

  .section {
    margin-bottom: 20px;
    .row {
      display: flex;
      align-items: center;
    }
    .title {
      min-height: 38px;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      align-items: center;
    }
    .sub-title {
      color: $clrNoData2;
      font-size: 14px;
    }
    .input-title {
      margin: 5px 0;
      font-size: 16px;
      width: 350px;
    }
    .indentation-level-1 {
      margin: 0px 16px;
    }
    .input-sub-title {
      margin: 5px 0;
      font-size: 14px;
      min-width: 110px;
    }
    .inputs-row {
      display: flex;
      gap: 10px;
      align-items: center;

      &.mb-3 {
        margin-bottom: 15px;
      }
    }
  }
  .SwitchInput {
    .Switch {
      .Mui-checked {
        color: $clrMain;
      }

      .MuiSwitch-switchBase.Mui-disabled {
        color: $clrMain;
        opacity: 0.5;
      }

      .Mui-checked + .MuiSwitch-track {
        background-color: $clrMain;
      }
    }
  }
}
