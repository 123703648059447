@import "./../../../../style/setup/variables.scss";

$mainBgColor: $clrWhite2;
$mainBorderColor: $lightGray2;

.ValidationsConfig {
  margin: 16px;
}

.BookPager {
  .actionButton:nth-child(odd),
  .actionButton:nth-child(even) {
    text-transform: none;
    color: $clrWhite;
    background: $clrBlack;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }
  .actionButton:nth-child(even) {
    color: $clrWhite;
    background: $clrMain;
  }
  .firstRow {
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 20px;
    height: 80vh;
    width: 100%;

    .areaA {
      flex: 0 0 935px;
      height: 100%;
      display: flex;
      background: $clrWhite;
      border-radius: 5px;
      overflow: hidden;
      line-height: 1;
      justify-content: start;
    }
    .areaB {
      height: 100%;
      overflow-y: hidden;
      flex: 0 0 800px;
    }
    &.oneArea {
      display: block;
      .areaB {
        display: none;
      }
    }
  }

  .secondRow {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    .areaActions {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  &.modalHeight {
    height: 75vh;
    .firstRow {
      height: 85%;
    }
  }

  .hide {
    display: none;
  }
}
