@import "../../style/setup/variables.scss";
@import "../../style/setup/mixins.scss";

.client-page {
  width: $mainPagesWidth;
  position: relative;
  max-width: 100%;
  margin-top: 50px;
  .info-and-contacts {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-inline-end: 50px;
    }
    .grid-container {
      display: grid;
      grid-template-rows: repeat(3, 92px);
      grid-auto-flow: column;
      grid-column-gap: 22px;
      align-items: center;
      .contact {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .icn-wrapper {
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: $icnSize;
          margin-inline-end: 20px;
        }
        .contact-info-wrapper {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 472px;
          height: 64px;
          .contact-info {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background-color: $clrWhite;
            transition: 0.3s;
            box-shadow: $cardShadow;
            border-radius: $borderRadius;
            padding-inline-start: 22px;
            font-size: 36px;
            color: $clrMainDark;
            transition: 0.3s;
            cursor: default;
          }
          .contact-info:hover {
            box-shadow: $cardShadowHover;
          }
          .remove-btn {
            cursor: pointer;
            position: absolute;
            z-index: 4;
            top: -13px;
            right: -13px;
            font-size: 26px;
            color: $clrNegative;
            cursor: pointer;
            transition: 0.3s;
          }
          // .remove-btn:hover {
          //     top: -17px;
          //     right: -17px;
          // }
        }
      }
      .add-contact-btn {
        width: 312px;
        height: 64px;
        background-color: $clrPositive;
        border-radius: $borderRadius;
        box-shadow: $cardShadow;
        font-family: main-font-regular;
        color: $clrWhite;
        font-size: 36px;
        transition: 0.3s;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-inline-start: 22px;
        line-height: 10px;
        margin-inline-start: 70px;
        svg {
          margin-inline-end: 22px;
        }
      }
      .add-contact-btn:hover {
        @include darken-button($clrPositive);
      }
    }
  }
  .notes-and-remove-btn {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 91%;
    .notes {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .icn-wrapper {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $icnSize;
        margin-inline-end: 20px;
      }
      .notes-info {
        width: 408px;
        height: 312px;
        padding: 5px 22px;
        background-color: $clrWhite;
        border-radius: $borderRadius;
        box-shadow: $cardShadow;
        transition: 0.3s;
        overflow-y: scroll;
        cursor: pointer;
        p {
          height: 100%;
          width: 100%;
          outline: none;
          text-align: start;
          font-size: 32px;
          color: $clrMainDark;
          line-height: 40px;
        }
        &.outlined {
          border: 1px solid #b5b5b5;
        }
      }
      .notes-info:hover {
        box-shadow: $cardShadowHover;
      }
      .notes-info::-webkit-scrollbar {
        display: none;
      }
    }
    .remove-entity-btn {
      align-self: flex-end;
      margin-bottom: 46px;
    }
  }
  .add-contact-form-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
}
