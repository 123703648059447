@import "../../style/setup/variables.scss";

.add-contact-form-wrapper {
  background-color: $clrBrightBg;
  .add-contact-form {
    display: grid;
    grid-template-columns: 506px 506px;
    grid-column-gap: 64px;
    grid-row-gap: 57px;
    button {
      margin-top: 7px;
    }
  }
}
