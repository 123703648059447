@import "../setup/variables.scss";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0px;
  background-color: $clrBrightBg;
  font-family: main-font-regular;
  @include scrollbar-styles;
  margin: 0;
  padding: 0px;
  background-color: $clrMediumBg;
  direction: rtl;
  font-family: main-font-regular;
  overflow-x: hidden;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: main-font-regular;
  text-align: center;
}

.ltr {
  direction: ltr;
  .MuiDialogActions-spacing {
    direction: ltr;
  }
}

.rtl {
  direction: rtl;
}

button {
  border: none;
  cursor: pointer;
  outline: none;
}

input {
  outline: none;
}

.loadingLogo {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: $clrMediumBg;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  top: 0;
  transition: 400ms all;
  .logo {
    width: 100px;
    height: 100px;
    path {
      fill: #aeaeae;
    }
  }
  &.hidden {
    .logo {
      transition: 300ms all;
      transform: scale(1.1);
    }
    transition: 300ms all;
    opacity: 0;
    visibility: hidden;
  }
}

.sumitLogoContainer {
  display: flex;
  margin-top: 50px;
  justify-content: center;
  .sumitLogo {
    width: 200px;
  }
}

.MuiToggleButtonGroup-root {
  direction: ltr;
}

.MuiSwitch-root {
  .MuiSwitch-colorSecondary.Mui-checked {
    color: $clrMain;
    + .MuiSwitch-track {
      background-color: darken($clrMain, 5);
    }
  }
}

.MuiTableSortLabel-root {
  // a temporary fix for broken archive table header
  display: none !important;
}

.MuiTableCell-root {
  font-family: main-font-regular !important;
}

.link {
  color: $linkColor;
  cursor: pointer;
}

.styledForm {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  &.centered {
    align-items: center;
  }
  .formError {
    width: fit-content;
    height: 21px;
    color: $clrNegative;
    border-bottom: 1px solid $clrNegative;
    margin-bottom: 10px;
  }
}
