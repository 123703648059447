@import "../../style/setup/variables.scss";

.PopupDropdown {
  position: relative;
  &.newMeeting {
    .selectedLabel {
      background-color: $clrWhite;
    }
  }
  .selectedLabel,
  .dropdownContainer {
    border-radius: $borderRadius;
    border: 1px solid $newGray;
  }
  .selectedLabel {
    height: 100%;
    padding: $normalPadding;
    font-family: main-font-regular;
    font-size: 1rem;
    line-height: 1rem;
    color: $newBlack;
    cursor: pointer;
    &.placeholder {
      color: $gray;
    }
  }
  .search {
    width: 100%;
    padding: 0 8px;
    input {
      width: 100%;
      height: 35px;
      padding: 10px;
      background-color: $lightGray;
      border: solid 1px $lightGray;
      border-radius: 100px;
    }
  }
  .optionsContainer::-webkit-scrollbar {
    display: none;
  }
  .optionsContainer {
    overflow-x: hidden;
    height: 135px;
    overflow-y: scroll;
    .option {
      cursor: pointer;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      text-align: start;
      gap: 5px;
      input[type="checkbox"] {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        margin: 1px 2px;
        &:after {
          width: 11px;
          margin: 1px 2px;
        }
      }
      &.checkbox {
        display: flex;
        align-items: center;
        cursor: default;
        input,
        label {
          cursor: pointer;
        }
        label {
          width: 100%;
          height: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &:hover {
        background-color: #e3e3e3;
      }
      &.rtl {
        direction: rtl;
      }
      &.ltr {
        direction: ltr;
      }
    }
  }
}
