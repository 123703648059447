@import "../../../style/setup/variables.scss";

.ButtonComponent {
  width: fit-content;
  transition: 1s all;
  .button {
    width: 100%;
    height: 37px;
    padding: 6px 10px;
    background: #222936;
    font-size: 1.1rem;
    color: #fff;
    border-radius: 8px;
    border: 0;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    &:disabled {
      cursor: default;
      background-color: $disabledColor;
    }
  }
  &.loading {
    .MuiCircularProgress-circle {
      color: $newBlack;
    }
    .button {
      background-color: $disabledColor;
    }
  }
  .countdown,
  .loading {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(1.5px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    color: black;
    transition: 400ms all;

    &.hidden {
      opacity: 0;
      transition: 400ms all;
    }
  }
}
.fullWidth {
  width: 100%;
}
