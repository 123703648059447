@import "../../style/setup/variables.scss";

.main-container.TranslationsEditor {
  align-items: flex-start;
  padding: 50px;
  display: flex;
  .header {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $newGray;
    position: sticky;
    top: 70px;
    background-color: $clrMediumBg;
    height: 50px;
    .title {
      display: flex;
      align-items: flex-end;
      flex: 1;
      font-size: 1.3rem;
      font-weight: 700;
      padding: 5px 10px;
    }
    .approveButton {
      position: absolute;
      top: 0;
      height: 30px;
      width: 100px;
      background-color: red;
    }
    .saveButton {
      width: 80px;
    }
  }
  .translationString {
    display: flex;
    margin-bottom: 3px;
    .translationKey {
      cursor: default;
    }
    .translationKey,
    .languageKeyValue {
      padding: 0 10px;
      flex: 1;
    }
    .languageKeyValue {
      .EditableText {
        width: 100%;
        .inputContainer {
          width: 100%;
        }
      }
    }
    .spacer {
      width: 80px;
    }
    &:hover {
      background-color: #d2d2d2;
    }
  }
}

.ltr .approveButton {
  right: 0;
}
.rtl .approveButton {
  left: 0;
}
