@import "./../../../../style/setup/variables.scss";

.TextDecorationSelection {
  .selectionContent {
    font-size: 14px;
    font-family: "main-font-regular";
    line-height: initial;
    letter-spacing: initial;
    text-transform: initial;
  }
}
