@import "../../style/setup/variables.scss";

.drop-zone {
  width: 100%;
  height: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  border: dashed 16px $clrNoData1;
  outline: none;
  cursor: pointer;
  h1 {
    font-size: 80px;
    font-family: main-font-extra-bold;
    color: $clrNoData1;
  }
  .icn-wrapper {
    font-size: 213px;
    color: $clrNoData1;
  }
}
