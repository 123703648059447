@mixin aspect-ratio($width, $height) {
  position: relative;
  overflow: hidden;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  > img {
    height: 100%;
    object-fit: cover;
  }
}

@mixin darken-button($clr) {
  background-color: darken($clr, 7%);
}

@mixin darken-button-hollow($clr) {
  color: darken($clr, 7%);
  border: 2px solid darken($clr, 10%);
  background-color: lighten($clr, 50%);
}

@mixin scrollbar-styles {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 999px;
    background-color: $clrBrightBg;
  }
  &::-webkit-scrollbar {
    width: 0.8rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $clrMainDark;
    border-radius: 999px;
    border: 1px solid rgba(255, 255, 255, 0.4);
  }
}
