@import "../../style/setup/variables.scss";

.nav-btn {
  width: 40px;
  height: 25px;
  cursor: pointer;
  .line {
    height: 5px;
    border-radius: 999px;
    background-color: $clrMain;
    transition: 0.2s ease-in-out;
    &.line1 {
      width: 85%;
    }
    &.line2 {
      width: 100%;
    }
    &.line3 {
      width: 70%;
    }
  }
  &.open {
    .line {
      width: 100%;
    }
  }
}
.nav-btn:hover {
  .line {
    width: 100%;
  }
}
