@import "../../style/setup/variables.scss";
@import "../../style/setup/mixins.scss";

.transcribers-list {
  z-index: 2;
  top: 0;
  height: 100%;
  padding: 0 30px;
  padding-top: 34px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
  background-color: $clrWhite;
  border-radius: $borderRadius;
  flex-basis: 72%;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 19px;
    background-color: $clrWhite;
    flex-basis: 28%;
    overflow: hidden;
    .title {
      height: 100%;
      width: 100%;
      // border: 1px solid;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-bottom: 13px;
      cursor: pointer;
      h3 {
        text-overflow: ellipsis;
        margin: 0;
        padding: 0;
        font-family: main-font-regular;
        font-size: 1.25rem;
        color: $clrMainDark;
        line-height: 1.5rem;
        height: 100%;
        width: 100%;
      }

      .back-icn-wrapper {
        font-size: 25px;
        margin-inline-end: 2px;
      }
    }

    .search-bar {
      width: 100%;
      height: 32px;
      box-shadow: none;
      border-radius: 14px;
      background-color: #d6d6d6;

      input {
        width: 87%;
        background-color: #d6d6d6;
        border-radius: 14px 0 0 14px;
        padding-bottom: 7px;
        font-size: 26px;
        color: $clrBlack;
      }

      ::placeholder {
        color: $clrInputPlaceholder;
      }
    }
  }

  .list {
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: scroll;
    height: 244px;
    width: 100%;

    .item {
      display: flex;
      align-items: center;
      width: 100%;

      p {
        font-family: main-font-light;
        font-size: 26px;
        line-height: 26px;
        padding-bottom: 5px;
      }
    }

    .item:hover {
      cursor: pointer;

      .dot {
        width: 13px;
        height: 13px;
      }
    }
  }

  .list::-webkit-scrollbar {
    display: none;
  }

  .MuiCircularProgress-root {
    margin-top: 50px;

    .MuiCircularProgress-svg {
      .MuiCircularProgress-circle {
        stroke: $clrMainDark;
      }
    }
  }
}

h3.name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
