@import "../../style/setup/variables.scss";
@import "../../style/setup/mixins.scss";

.remove-entity-btn {
  position: relative;
  width: 250px;
  height: 80px;
  background-color: $clrNegative;
  box-shadow: $cardShadow;
  border-radius: $borderRadius;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
  h4 {
    font-size: 36px;
    color: $clrWhite;
    font-family: main-font-regular;
    font-weight: normal;
  }
  .approve-removal-modal {
    padding: 0 12px;
    cursor: default;
    background-color: $clrWhite;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 13px;
    transition: 0.3s;
    p {
      font-family: main-font-regular;
      font-size: 26px;
      line-height: 32px;
      margin-bottom: 15px;
      span {
        color: $clrMain;
      }
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 198px;
      button {
        width: 88px;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $borderRadius;
        font-family: main-font-regular;
        font-size: 26px;
        padding-bottom: 3px;
      }
    }
  }
}
.add-entity-btn:hover {
  @include darken-button($clrNegative);
}
