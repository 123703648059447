@import "../../../style/setup/variables.scss";

.TimeTrackModal {
  .bodyContainer {
    display: flex;
    .timeTrackBox {
      width: 300px;
      margin-inline-end: 10px;
      padding-inline-end: 10px;
      &.transcriberTimeTrack {
        border-inline-end: 1px solid $gray;
      }
      .title {
        font-size: 1.2rem;
        text-decoration: underline;
      }
      .timeTrackEvent {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
