@import "../../style/setup/variables.scss";
@import "../../style/setup/mixins.scss";

.nav-menu {
  width: 20vw;
  min-height: 350px;
  padding-bottom: 15px;
  background-color: $clrMainDark;
  border-radius: 0px 0px 0px 17px;
  position: fixed;
  top: $topBarHeight;
  z-index: 100;
  transform: translateX(100%);
  transition: 0.4s;
  overflow-y: auto;
  max-height: 85vh;
  padding: 20px;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  @include scrollbar-styles();
  .menuItems {
    display: flex;
    flex-direction: column;
    a {
      font-family: main-font-light;
      text-decoration: none;
      font-size: 1.3rem;
      color: $clrNavLink;
      width: 100%;
      transition: 0.2s;
      margin: 0.3rem 0;
      &:hover {
        color: $clrMain;
        cursor: pointer;
      }
    }
    &.settingsOpen a {
      transform: translateX(0%);
      transform: translateX(-100%);
    }
  }
  .versionContainer {
    display: flex;
    justify-content: space-between;
    .version {
      color: rgb(138, 138, 138);
      cursor: default;
    }
    .userSettingsButton {
      svg {
        cursor: pointer;
        path {
          color: white;
        }
      }
    }
  }
  .active {
    color: $clrMain;
  }
  .userSettingsContainer {
    height: auto;
    width: 100%;
    position: absolute;
    bottom: 40px;
    top: 0;
    right: 0;
    overflow: hidden;
    padding: 20px;
    background-color: $clrMainDark;
    transform: translateX(100%);
    transition: 200ms;
    .platform_language {
      .settingLabel {
        flex-grow: 1;
      }
    }
    .setting {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .languageDropdown {
        .placeholder {
          color: white;
        }
        .selectedLabel {
          background-color: white;
          color: rgba(0, 0, 0, 0.38);
        }
        width: auto;
        height: auto;
        margin: 0px;
        flex-grow: 1;
      }
      .settingLabel {
        color: white;
        font-size: 1.1rem;
      }
      .MuiToggleButtonGroup-root {
        .MuiToggleButtonGroup-grouped {
          height: 30px;
          width: 30px;
          &.Mui-selected {
            background: $clrMain;
          }
        }
      }
    }
    &.open {
      transform: translateX(0%);
      transition: 200ms;
    }
  }
  &.open {
    transform: translateX(0%);
  }
}

.ltr {
  .nav-menu {
    transform: translateX(-100%);
    border-radius: 0px 0px 17px 0px;
    &.open {
      transform: translateX(0%);
    }
  }
}
