@import "../../../style/setup/variables.scss";

.Audit {
  display: flex;
  justify-content: space-around;
  margin-bottom: 8px;
  .typeAndTriggeredBy {
    width: 200px;
    .type {
      font-weight: bold;
    }
    .name {
      color: $clrLigthBlue;
    }
  }
  .dateAndTime {
    .time {
      color: $clrLigthBlue;
    }
    .date {
      font-weight: bold;
    }
  }
}
