@import "../../style/setup/variables.scss";

.add-entity-card {
  width: 384px;
  height: 152px;
  background-color: $clrMediumBg;
  box-shadow: $cardShadow;
  border-radius: $borderRadius;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  .status-bar {
    height: 16px;
    width: 100%;
    background-color: $clrPositive;
  }
  .content-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      line-height: 36px;
      p {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 36px;
        color: $clrTextMediumGrey;
        &.sub {
          font-size: 26px;
          color: $clrMain;
        }
      }
    }
  }
}
.add-entity-card:hover {
  box-shadow: $cardShadowHover;
}
