@import "../../style/setup/variables.scss";

.payment-preview {
  width: 384px;
  height: 152px;
  background-color: $clrWhite;
  box-shadow: $cardShadow;
  border-radius: $borderRadius;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
  .status-bar {
    height: 16px;
    width: 100%;
    background-color: $clrMain;
    &.positive {
      background-color: $clrPositive;
    }
    &.negative {
      background-color: $clrNegative;
    }
    &.main {
      background-color: $clrMain;
    }
  }
  .details {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    padding: 6px 0;
    h4 {
      font-family: main-font-semi-bold;
      font-size: 26px;
      color: $clrMainDark;
    }
    .subtitle-wrapper {
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icn-wrapper {
        font-size: 19px;
        margin-inline-end: 5px;
      }
      p {
        font-family: main-font-regular;
        color: $clrMainDark;
        font-size: 26px;
        padding-bottom: 4px;
      }
    }
    .sum {
      font-size: 36px;
      font-family: main-font-light;
      color: $clrTextMediumGrey;
    }
    .btns-container {
      height: 100%;
      width: 107px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 13px;
      .invoice-btn {
        cursor: pointer;
        transition: 0.3s;
        display: flex;
        align-items: center;
        background-color: rgba(255, 0, 0, 0);
        border: 2px solid $clrMainDark;
        width: 107px;
        height: 40px;
        border-radius: $borderRadius;
        font-size: 15px;
        font-family: main-font-regular;
        padding-inline-start: 10px;
        line-height: 0px;
        .icn-wrapper {
          font-size: 18px;
          margin-inline-end: 2px;
        }
      }
      .invoice-btn:hover {
        background-color: $clrMediumBg;
      }
      .pay-btn {
        background-color: $clrMainDark;
        width: 56px;
        height: 24px;
        border-radius: $borderRadius;
        color: $clrWhite;
        font-size: 15px;
        padding-bottom: 3px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 96px;
        transition: 0.2s;
        &.client {
          position: static;
          margin: 0;
          align-self: center;
        }
      }
      .pay-btn:hover {
        width: 70px;
      }
    }
  }
  &.grey {
    background-color: $clrMediumBg;
  }
  &.clickable:hover {
    box-shadow: $cardShadowHover;
    cursor: pointer;
  }
}
