@import "./../../style/setup/variables.scss";

$mainBgColor: $clrWhite2;
$mainBorderColor: $lightGray2;

.setting-page {
  display: flex;
  padding: 35px 55px 35px 30px;
  flex-direction: column;

  .section {
    margin-bottom: 20px;
    .title {
      min-height: 38px;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      align-items: center;
    }
    .sub-title {
      color: $clrNoData2;
      font-size: 14px;
    }
    .input-title {
      margin: 5px 0;
      font-size: 14px;
      min-width: 110px;
    }
    .inputs-row {
      display: flex;
      gap: 10px;
      align-items: center;

      &.short {
        input {
          width: 100px;
        }
      }
      &.mb-1 {
        margin-bottom: 5px;
      }
      &.mb-3 {
        margin-bottom: 15px;
      }
    }
  }
  .SwitchInput {
    .Switch {
      .Mui-checked {
        color: $clrMain;
      }

      .MuiSwitch-switchBase.Mui-disabled {
        color: $clrMain;
        opacity: 0.5;
      }

      .Mui-checked + .MuiSwitch-track {
        background-color: $clrMain;
      }
    }
  }
}
