@import "../../style/setup/variables.scss";
.InfiniteGenericTable {
  width: 100%;
  .loading-spinner {
    align-self: center;
    justify-content: center;
  }
  .loading-error-container {
    display: flex;
    justify-content: center;
    font-weight: bold;
    .loading-error-text {
      cursor: pointer;
    }
  }
  .table-top {
    position: sticky;
    top: 70px;
    display: flex;
    background: $orangeTableHeader;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 10;
    .table-search-bar {
      width: 50%;
      display: flex;
      align-items: center;
      gap: 20px;
      background-color: $clrMain;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      padding: 0.8rem;
      .rowCount {
        font-size: 1.5rem;
      }
      .search-bar {
        width: 50%;
        input {
          border-radius: 999px;
        }
      }
    }
    .actions {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      padding-left: 10px;
      align-items: center;
      flex-direction: row-reverse;
      .action {
        padding: 0rem 1rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: #454545;
      }
      .action:hover {
        color: #000;
      }
    }
  }
  .infinite-scroll-component {
    overflow: visible !important;
  }
  .tableContainerComp::-webkit-scrollbar {
    display: none;
  }
  .tableContainerComp {
    scrollbar-width: none;
    overflow: visible;
    .tableContainer {
      .tableHeader {
        background-color: $clrMain;
        .headerCell {
          padding: $normalPadding;
          background-color: $clrMain;
          text-align: start;
        }
        .headerCell {
          cursor: pointer;
          font-size: 1.2rem;
        }
        .MuiTableCell-stickyHeader {
          top: 174px;
        }
      }
      .tableBody {
        background-color: $clrWhite;
        text-align: right;
        .tableRow {
          &.faded {
            cursor: default;
            background-color: #dedede;
          }
          &.disabled {
            background-color: #f0040473;
          }
          &.clickable {
            cursor: pointer;
          }
          .rowCell {
            font-size: 1rem;
            text-align: start;
            padding: $normalPadding;
            &.contextMenu {
              padding: 0px 0px 0px 16px;
              width: 64px;
              height: 64px;
            }
            .contextMenuContainer {
              background-color: transparent;
              background-repeat: no-repeat;
              border: none;
              cursor: pointer;
              overflow: hidden;
              outline: none;
              padding: 0px;
              width: 100%;
              height: 100%;
              .MuiButton-root {
                min-width: unset;
                padding: 0px;
                width: 100%;
                height: 100%;
              }
              .MuiButton-root:hover {
                background-color: inherit;
                .contextMenu {
                  color: black;
                }
              }
              .contextMenu {
                box-sizing: content-box;
                color: grey;
                font-size: 36px;
              }
            }
            .tdContainer {
              width: 100%;
              &.ellipsis {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .low {
              background-color: $statusBlue;
            }
            .medium {
              background-color: $statusOrange;
            }
            .high {
              background-color: $statusRed;
            }
            .tableActions {
              min-width: 80px;
              min-height: 50px;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              .actions {
                width: 100%;
                display: flex;
                justify-content: center;
              }
              .actionsText {
                text-align: center;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
              .actionButton {
                border-radius: 3px;
                padding: 5px 15px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
