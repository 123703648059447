$color-grey: grey;
$color-white: white;
$gradient-1: #f9f9f9;
$gradient-2: #e3e3e3;

.NoteModal {
  .bodyContainer {
    .note-file-row {
      padding: 5px 0px;
      .row-1 {
        display: flex;
        justify-content: space-between;
        padding: 10px 0px;
        .note-file-name {
          display: flex;
          cursor: pointer;
          .icon {
            margin-inline-end: 10px;
          }
          .name {
          }
        }
        .delete {
          cursor: pointer;
        }
      }
      .row-2 {
        display: flex;
        color: $color-grey;
        font-size: small;
        line-height: 0;
      }
    }

    .note-file-list {
      margin-top: 16px;
      margin-bottom: 16px;
      max-height: 150px;
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 0px 12px;
      a {
        color: inherit;
      }
    }
    .file-notes {
      display: flex;
      align-items: center;

      .loader-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .add-file-input-btn {
        display: inline-block;
        background: linear-gradient(top, $gradient-1, $gradient-2);
        border: 1px solid $color-grey;
        border-radius: 3px;
        padding: 5px 8px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        cursor: pointer;
        text-shadow: 1px 1px $color-white;
        font-weight: 700;
        font-size: 10pt;
      }
      .add-file-input {
        visibility: hidden;
        height: 0;
      }
      .upload-file-btn {
        cursor: pointer;
        border: 1px solid black;
        border-radius: 5px;
        padding: 5px 10px;
        color: $color-white;
        background: black;
      }
    }
    .note {
      margin-bottom: 16px;
    }
    .notes {
      display: flex;
      flex-direction: column;
      height: 100%;
      .textarea-container {
        position: relative;
        height: 100%;
        .textarea-spinner {
          position: absolute;
          bottom: 10px;
          &.ltr {
            right: 10px;
          }
          &.rtl {
            left: 10px;
          }
        }
      }
      textarea {
        width: 100%;
        resize: none;
        height: 200px;
        flex: 1;
        font-family: main-font-regular;
        font-size: 18px;
        resize: none;
        border-radius: 15px;
        border-color: $color-grey;
        padding: 12px;
      }
      .file-input {
      }
    }
  }
}
