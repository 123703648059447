@import "../../style/setup/variables.scss";

.RangeAnnotation {
  margin-bottom: 5px;
  color: #cbcbcb;
  .addAnnotationContainer {
    position: relative;
    .addAnnLabelContainer {
      width: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      margin: 0 40px;
      background-color: $clrMediumBg;
      cursor: pointer;
      transition: 200ms all;
      &:hover {
        color: $newBlack;
        transition: 200ms all;
        & + .addAnnotationLine {
          background-color: #cdcdcd;
          transition: 200ms all;
        }
      }
    }
    .addAnnotationLine {
      width: 100%;
      height: 1px;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: #e1e1e1;
      z-index: -1;
      transition: 200ms all;
      outline: 0;
    }
  }
  .annotation {
    display: flex;
    gap: 10px;
    margin: 10px 0;
    color: $newBlack;
    font-family: main-font-regular;
    font-size: 1.1rem;
    position: relative;
    .annotationTypeContainer {
      min-width: 190px;
      background-color: white;
      padding: 8px 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      .deleteAnnotation {
        color: $newGray;
        &:hover {
          color: $newBlack;
        }
      }
      .typeName {
        text-align: center;
      }
      .typeIcon {
        justify-self: flex-end;
        transition: 100ms ease-in-out;
        &.open {
          transform: rotate(-90deg);
          transition: 100ms ease-in-out;
        }
      }
    }
    .typeSelector {
      min-width: 190px;
      max-height: 0;
      right: 0;
      top: calc(100% - 8px);
      position: absolute;
      background-color: white;
      padding: 0;
      text-align: center;
      z-index: 10;
      overflow: hidden;
      transition: 500ms all ease;
      outline: 0;
      &.new {
        top: 0;
      }
      .typeOption {
        cursor: pointer;
        font-family: main-font-noraml;
        font-size: 1rem;
        transition: 200ms all;
        padding: 3px 0;
        &.focused {
          margin-inline-start: 5px;
          font-weight: 700;
        }
        &:focus,
        &:hover {
          margin-inline-start: 5px;
          transition: 200ms all;
        }
      }
      &.open {
        padding: 8px;
        max-height: 800px;
        overflow-y: scroll;
        box-shadow: 2px 2px 2px $newGray;
        transition: all 500ms ease;
      }
    }
    .annotationContent {
      width: 100%;
      min-height: inherit;
      position: relative;
      .textRangeField {
        width: 100%;
        height: 100%;
        padding: 8px 20px;
        position: absolute;
        font-family: main-font-semi-bold;
        font-size: 18px;
        letter-spacing: 1.2px;
        border: 0;
        resize: none;
        box-sizing: border-box;
        unicode-bidi: embed;
        &:focus {
          outline: 0;
          box-shadow: 2px 0px 0px $gray;
        }
      }
      .dummyRange {
        direction: rtl;
        unicode-bidi: embed;
        width: 100%;
        padding: 8px;
        font-family: "main-font-regular";
        font-size: 18px;
        letter-spacing: 1.2px;
      }

      &.disabled {
        background: #f7f7f7;
      }
    }
  }
}
