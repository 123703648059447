@import "../../../style/setup/variables.scss";

.CircleProgress {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .progressLabel,
  .background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .background {
    width: 100%;
    height: 100%;
    clip-path: circle(20px at center);
    background-color: #dedede;
    border-radius: 80px;
  }

  .progressLabel {
    color: $newBlack;
    font-size: 0.8rem;
  }
  .MuiCircularProgress-colorPrimary {
    color: $clrMain;
  }
  &.lg {
    width: 60px;
    height: 60px;
    .background {
      clip-path: circle(30px at center);
    }
    .progressLabel {
      font-size: 1.1rem;
    }
  }
}
