@import "../../style/setup/variables.scss";

.SpeakerSamples {
  width: 100%;
  padding: 15px;
  background: white;
  margin-bottom: 10px;
  margin-top: 10px;
  .speakers {
    display: flex;
    flex-wrap: wrap;
    max-height: 170px;
    overflow: scroll;
    overflow-x: hidden;
    justify-content: space-between;
    .speaker {
      width: 50%;
      display: flex;
      gap: 5px;
      margin-bottom: 5px;
      .playButton {
        width: 20px;
        height: 20px;
        color: darken($color: $clrMain, $amount: 20);
        display: flex;
        align-items: center;
        padding: 4px;
        background-color: $clrMain;
        border-radius: 50%;
        cursor: pointer;
        &.stop {
          justify-content: center;
          padding: 0;
        }
      }
      .speakerName {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .loading {
    color: $newGray;
  }
}
