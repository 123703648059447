@import "../../style/setup/variables.scss";
@import "../../style/setup/mixins.scss";

.submit-btn {
  transition: 0.3s;
  width: 384px;
  height: 112px;
  display: flex;
  align-items: center;
  border-radius: $borderRadius;
  background-color: $clrPositive;
  color: $clrWhite;
  font-family: main-font-regular;
  font-size: 36px;
  margin-inline-start: 72px;
  padding-inline-start: 50px;
  svg {
    margin-inline-end: 24px;
    height: 50px;
    width: 50px;
    fill: $clrWhite;
  }
}

.submit-btn:hover {
  @include darken-button($clrPositive);
}
