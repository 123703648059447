.slider {
  -webkit-appearance: none;
  outline: none;
  width: 90%;
  height: 5px;
  border-radius: 99px;
  background: rgb(245, 245, 245);
  border: 1px solid rgb(190, 190, 190);
  cursor: pointer;
  // box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.233);
}

.slider:hover {
  background: rgb(221, 221, 221);
}

.slider:checked {
  background: $clrMainDark;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 14px;
  height: 14px;
  border-radius: 99px;
  background: $clrMainDark;
}
.slider::-webkit-slider-thumb:hover {
  @include darken-button($clrMainDark);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.274);
}
