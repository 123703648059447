@import "../../style/setup/variables.scss";

.input-wrapper {
  display: flex;
  align-items: center;
  input {
    height: 64px;
    font-family: main-font-regular;
    font-size: 32px;
    color: $clrTextMediumGrey;
    box-shadow: $cardShadow;
    border-radius: $borderRadius;
    border: none;
    padding-inline-start: 22px;
    padding-bottom: 3px;
    outline: none;
    &.ltr {
      direction: ltr;
      text-align: end;
      padding-inline-end: 22px;
    }
    &.date {
      text-align-last: right;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    padding-inline-start: 18px;
  }
  ::placeholder {
    color: $clrInputPlaceholder;
  }
  .icn-wrapper {
    height: 50px;
    width: 50px;
    margin-inline-end: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $clrMain;
    font-size: $icnSize;
  }
}
