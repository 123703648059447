@import "../../../style/setup/variables.scss";

.MuiDialog-container {
  .paperDefaultSize {
    &.ExportModal {
      width: 400px;
      min-height: 250px;
    }
  }
}
.ExportModal {
  .embedding_msg {
    display: flex;
    color: #ff000075;
    font-size: 14px;
  }
  .checkboxContainer,
  .toggleContainer,
  .inputContainer {
    margin-top: 10px;
    display: flex;
    label {
      align-self: center;
      color: black;
    }
    &.disabled {
      opacity: 0.6;
    }
  }
  .field {
    margin-top: 10px;
    .label {
      color: $newBlack;
      text-align: inherit;
    }
    &.rtl {
      text-align: right;
    }
    &.ltr {
      text-align: left;
    }
  }
}
