@import "../../style/setup/variables.scss";

.textarea-wrapper {
  display: flex;
  align-items: flex-start;
  textarea {
    height: 64px;
    font-family: main-font-regular;
    font-size: 32px;
    color: $clrTextMediumGrey;
    box-shadow: $cardShadow;
    border-radius: $borderRadius;
    border: none;
    padding-inline-start: 22px;
    padding-bottom: 3px;
    outline: none;
  }
  ::placeholder {
    color: $clrInputPlaceholder;
  }
  .icn-wrapper {
    height: 50px;
    width: 50px;
    margin-inline-end: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $clrMain;
    font-size: $icnSize;
  }
}
