@import "./../../../../style/setup/variables.scss";

$mainBgColor: $clrWhite2;
$mainBorderColor: $lightGray2;
.pageListContainer {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 0 0 25%;
  .page {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 14px 22px;
    font-size: 16px;
    cursor: pointer;

    &:hover,
    &.selected {
      background: $clrMain;
      color: $clrWhite;
    }
  }

  .bottomButtons {
    .addLangButton {
      width: 100%;
      text-align: center;
      padding: 16px 0 16px 0;
      transition: all 600ms;
    }
    .addLangButton:hover {
      cursor: pointer;
      background: $clrMain;
    }
  }
}

.pageWrapper {
  border-inline-start: 1px solid $mainBorderColor;
  height: auto;
  overflow-y: auto;
  flex: 0 0 75%;
}
