@import "../../style/setup/variables.scss";

.select-wrapper {
  display: flex;
  align-items: center;
  z-index: 1;
  position: relative;
  width: 320px;
  .select {
    width: 100%;
    p {
      padding-bottom: 4px;
    }
    .main {
      width: 100%;
      height: 100%;
      background-color: $clrWhite;
      display: flex;
      align-items: center;
      padding-inline-start: 8px;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      &.placeholder {
        p {
          color: $clrInputPlaceholder;
        }
      }
    }
    .drop-down {
      width: 100%;
      background-color: $clrWhite;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-items: flex-start;
      overflow: scroll;
      z-index: 2;
      // position: absolute;
      width: 100%;
      .option {
        cursor: pointer;
        padding-inline-start: 22px;
        height: 30px;
        min-height: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .dot-container {
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          .dot {
            width: 8px;
            height: 8px;
            border-radius: 99px;
            transition: 0.2s;
            background-color: $clrPositive;
          }
        }
      }
      .option:hover {
        .dot {
          width: 13px;
          height: 13px;
        }
      }
      .loading-spinner {
        height: 64px !important;
      }
    }
    .drop-down::-webkit-scrollbar {
      display: none;
    }
    p {
      font-family: main-font-regular;
      font-size: 18px;
      color: $clrTextMediumGrey;
    }
  }
  .icn-wrapper {
    height: 50px;
    width: 50px;
    margin-inline-end: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $clrMain;
    font-size: $icnSize;
  }
  &.center {
    .select {
      .main {
        justify-content: center;
        padding-inline-start: 0;
      }
    }
  }
  &.disabled {
    .select {
      .main {
        background-color: $clrMediumBg;
      }
    }
  }
}
