@import "./../../../../style/setup/variables.scss";

.TextInput {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: fit-content;
  input {
    border-radius: $borderRadius;
    border: 1px solid $clrNoData1;
    padding: 7px 8px;
    width: 100%;
  }

  p.error {
    color: $clrNegative;
    font-size: small;
    text-align: start;
  }
}
