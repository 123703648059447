@import "./../../style/setup/variables.scss";

$media-v3-max-height: 140px;
$media-v3-transition-speed: 0.4s;

.MediaPlayerV3 {
  &.ltr {
    .media-player-v3-content {
      right: 100px;
    }
  }
  &.rtl {
    .media-player-v3-content {
      left: 100px;
    }
  }
  font-size: 16px;
  font-weight: bolder;
  filter: drop-shadow(0px 3px 10px $clrInputPlaceholder);
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: $media-v3-max-height;
  transition: height $media-v3-transition-speed ease-out;

  &.close {
    height: 60px;
  }

  .media-player-v3-content {
    position: absolute;
    direction: inherit;
    background: black;
    border-radius: 10px 10px 0 0;
    transition: bottom $media-v3-transition-speed ease-out;
    z-index: 1;
    bottom: $media-v3-max-height;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    .videoContainer {
      width: 100%;
      height: 100%;
      position: relative;
    }

    .videoPlaceholder {
      width: 30%;
      height: 30%;
      color: $clrWhite;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
    .gripResize {
      color: $clrWhite;
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      z-index: 3;
      margin: auto;
      height: 100%;
      font-size: 30px;
    }
    .replay,
    .list-sources {
      color: $clrWhite;
      font-size: 20px;
      position: absolute;
      top: 15px;
      right: 15px;
      cursor: pointer;
      border: 2px solid $clrWhite;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;
      filter: drop-shadow(0px 3px 15px black);
      transition: visibility $media-v3-transition-speed ease-out,
        opacity $media-v3-transition-speed ease-out;
      visibility: visible;
      opacity: 1;
    }

    .list-sources {
      right: unset;
      left: 15px;
    }

    .list-sources-container {
      position: absolute;
      background: $clrWhite;
      left: 35px;
      top: 65px;
      box-shadow: 1px 1px 15px $clrInputPlaceholder;
      text-align: start;
      border-radius: 3px;
      opacity: 0.9;
      z-index: 3;
      overflow: hidden;
      max-height: 190px;
      overflow-y: scroll;
      .sourceItem {
        display: flex;
        gap: 10px;
        padding: 8px 0;
        padding-inline-end: 30px;
        padding-inline-start: 10px;
        cursor: pointer;
        &:hover,
        &.selected {
          background: $clrMain;
          color: $clrWhite;
        }
      }

      &.hidden {
        display: none;
      }
    }

    video {
      width: 100%;
      height: 100%;
    }

    .videoSubtitles {
      position: absolute;
      bottom: 30px;
      color: $clrWhite;
      text-align: center;
      font-size: 1rem;
      font-weight: 600;
      font-family: Arial, Helvetica, sans-serif;
      text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black,
        0 0 5px #000;
      direction: rtl;
      width: 100%;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      .liveCaptionEditing,
      .rangeString {
        margin-bottom: 3px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        &.overlapping {
          color: $clrNeutral;
        }
        &.ltr {
          direction: ltr;
        }
        .passedWords {
          color: $clrNeutral;
        }
      }
    }

    &.close {
      bottom: -200px;
      .replay,
      .list-sources {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  .mediaPlayerV3Controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    color: $clrMainDark;
    background: $clrBlueWhite;
    z-index: 2;
    position: absolute;
    height: 35%;
    top: 0;
    transition: height $media-v3-transition-speed ease-out;
    &.close {
      height: 100%;
    }
    .toggle {
      cursor: pointer;
      transition: transform $media-v3-transition-speed ease-out;
      &.close {
        transform: rotate(180deg);
      }
    }

    .minimize {
      margin: 0 35px;
      cursor: pointer;
    }

    .part-3 {
      display: flex;
      width: 100%;
      justify-content: end;
      align-items: center;
    }

    .time {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      .speed-control {
        position: relative;
        margin-inline-start: 70px;
        display: flex;
        direction: ltr;
        width: 80px;
        justify-content: center;
        align-items: center;

        svg {
          margin: 0px 10px;
        }

        .speed-control-section {
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          justify-content: center;
          display: flex;
          flex-direction: column;
          text-align: center;
          font-size: 12px;
          bottom: calc(0.35 * #{$media-v3-max-height} - 12px);
          box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.1);
          cursor: pointer;
          color: $clrWhite;
          transition: all $media-v3-transition-speed;
          visibility: hidden;
          opacity: 0;
          width: 60px;
          .speed {
            background: rgba(0, 0, 0, 1);
            padding: 5px 0;
            transition: all $media-v3-transition-speed;
            &:hover {
              background: rgba(0, 0, 0, 0.8);
              color: $clrWhite;
            }
          }
        }

        &:hover .speed-control-section {
          visibility: visible !important;
          opacity: 1 !important;
        }
      }

      .mediaTimeRange {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 120px;
        direction: ltr !important;

        .curr-time.unselectable {
          margin: 0 5px;
        }
      }
    }

    .controllers {
      display: flex;
      justify-content: center;
      direction: ltr;
      width: 100%;

      .left {
        margin: 0 10px;
        cursor: pointer;
        transition: transform $media-v3-transition-speed ease-out;
      }

      .right {
        margin: 0 10px;
        cursor: pointer;
        transition: transform $media-v3-transition-speed ease-out;
      }

      .play-pause {
        margin: 0 10px;
        cursor: pointer;
        transition: transform $media-v3-transition-speed ease-out;
      }
    }
  }
  .media-player-v3-arranger {
    padding: 0 0px;
    justify-content: center;
    align-items: center;
    color: $clrMainDark;
    width: 100%;
    background: #f2f4f6;
    z-index: 2;
    position: absolute;
    height: 65%;
    bottom: 0;
    overflow: hidden;
    transition: height $media-v3-transition-speed ease-out;
    &.close {
      height: 0%;
    }

    .WaveformRanges {
      height: 100% !important;
      padding: 0;

      .loaderContainer {
        .BarLoader {
          height: calc(0.65 * #{$media-v3-max-height}) !important;
        }
      }
    }
    #waveformWrapper {
      .waveformContainer {
        wave,
        canvas {
          height: calc(0.65 * #{$media-v3-max-height}) !important;
        }
      }
    }
  }

  &.draggable {
    z-index: 99;
    height: fit-content;
    top: 0;
    left: 0;
    font-size: small;

    .videoContainer {
      cursor: move;
    }

    .media-player-v3-content {
      //Overrides inline css from plugin re-resizable
      position: initial !important;
      .videoSubtitles {
        right: 0;
        left: 0;
        margin: auto;
      }

      &.close {
        .replay,
        .list-sources {
          visibility: visible !important;
          opacity: 1 !important;
        }
      }
    }

    .mediaPlayerV3Controls {
      height: 50px;
      width: 100%;
      position: initial;
      padding: 0 15px;
      .time {
        justify-content: space-around;
        .speed-control {
          margin: 0;
          width: fit-content;
        }
        .mediaTimeRange {
          min-width: fit-content;
        }
      }

      .part-3 {
        .minimize {
          margin: 0;
        }
      }
    }

    .media-player-v3-arranger {
      height: fit-content;
      width: 100%;
      position: initial;
    }
  }
}
