input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  border: 2px solid $clrMainDark;
  border-radius: 7px;
  cursor: pointer;
  outline: none;
  // display: flex;
}

input[type="checkbox"]:checked {
  background: $clrMainDark;
  border: none;
}

input[type="checkbox"]:checked:after {
  content: url(../../assets/icons/v.svg);
  display: block;
  width: 18px;
  margin-inline-start: 3px;
  margin-top: 5px;
}
