@import "../../style/setup/variables.scss";

.StepsComponent {
  width: 400px;
  // height: 70px;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 50px;
  .stepsLine {
    height: 2px;
    position: absolute;
    background-color: $newGray;
    top: 25px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    transition: 1s all;
    position: relative;
    .stepLabel {
      display: flex;
      position: absolute;
      bottom: 0;
      transform: translateY(100%);
      text-align: center;
    }
    cursor: default;
    .stepId {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.3rem;
      border: 2px solid $newGray;
      border-radius: 100%;
      background-color: white;
    }
    &.active .stepId {
      background-color: $clrMain;
      transition: 1s all;
    }
  }
  &.center {
    align-self: center;
  }
}
