@import "../../style/setup/variables.scss";

.error-modal {
  position: absolute;
  z-index: 6;
  margin: 0 auto;
  width: 100%;
  height: 35%;
  display: flex;
  flex-direction: column;
  background-color: $clrBrightBg;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  color: $clrNegative;
  p {
    font-family: main-font-light;
    font-size: 40px;
    padding-top: 22px;
    color: $clrMainDark;
  }
}
