@import "../../style/setup/variables.scss";

.sorting {
  height: 85px;
  .toggle-section {
    width: 230px;
    .sort-by-title {
      font-family: main-font-regular;
      font-size: 25px;
    }
    p {
      font-size: 20px;
    }
    .toggle-btn {
      margin: 0 7px;
    }
  }
  .icns-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .icn {
      cursor: pointer;
      margin: 0 8px;
      color: $clrBlack;
      font-size: 32px;
      &.aleph {
        height: 100%;
        padding-top: 10px;
        .icn-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            height: 25px;
          }
        }
      }
    }
    .active {
      color: $clrMain;
      &.aleph {
        .icn-wrapper {
          svg {
            fill: $clrMain;
          }
        }
      }
    }
  }
}
