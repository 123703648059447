@import "../../../style/setup/variables.scss";

.Datepicker {
  max-width: 300px;
  height: 35px;
  position: relative;
  margin-bottom: 10px;
  border-radius: $borderRadius;
  border: 1px solid $newGray;
  box-sizing: border-box;
  cursor: pointer;
  .selectedLabel {
    width: 100%;
    height: 100%;
    padding: 8px;
    line-height: 1rem;
    &.placeholder {
      color: #757575;
    }
  }
  .calendarContainer {
    display: none;

    .MuiPickersBasePicker-pickerView {
      min-width: 270px;
      max-width: 270px;
    }
  }
}
