@import "../../style/setup/variables.scss";
@import "../../style/setup/variables.scss";

.payments-page {
  width: $mainPagesWidth;
  max-width: 100%;
  margin: 0 25px;
  position: relative;
  .addPayment {
    font-size: 1.5rem;
    cursor: pointer;
    width: fit-content;
    direction: ltr;
    background: black;
    color: white;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .payments-grid-container {
    margin-top: 60px;
    width: 100%;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, 384px);
    gap: 70px;
  }
  .yearContainer {
    width: 100%;
    margin-bottom: 30px;
    .year {
      font-size: 2.3rem;
      margin-bottom: 10px;
    }
    .debtYearContainer {
      width: 100%;
      justify-content: center;
      display: grid;
      grid-template-columns: repeat(auto-fill, 384px);
      gap: 70px;
    }
  }
  .debtMonth {
    margin-bottom: 20px;
  }
  .add-debt-form-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
  }
}

.payment-dialog {
  background-color: $clrWhite;
  width: 560px;
  height: 440px;
  box-shadow: $cardShadow;
  border-radius: $borderRadius;
  padding: 56px 0;
  position: relative;
  .input-icn-wrapper {
    width: 100%;
    padding-inline-start: 63px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 29px;
    .icn {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      margin-inline-end: 22px;
      width: 28px;
      height: 28px;
    }
    .text-input {
      border: none;
      box-shadow: $centeredShadow;
      width: 336px;
      height: 64px;
      border-radius: $borderRadius;
      font-family: main-font-regular;
      font-size: 32px;
      color: $clrMainDark;
      padding-inline-start: 18px;
      direction: ltr;
      text-align: end;
    }
    .text-input::-webkit-outer-spin-button,
    .text-input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      padding-inline-start: 18px;
    }
  }
  .loading-spinner {
    position: absolute;
    top: 175px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    .MuiCircularProgress-root {
      color: $clrMainDark;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  ::placeholder {
    color: $clrInputPlaceholder;
  }
  .error-container {
    height: 34px;
    width: 100%;
    margin-bottom: 63px;
  }
  p {
    text-align: start;
    width: 100%;
    padding-inline-start: 63px;
    font-size: 26px;
    &.error {
      color: $clrNegative;
    }
    &.content {
      margin-bottom: 34px;
    }
  }
  .btns-container {
    width: 480px;
  }
  .checkbox-wrapper {
    margin-bottom: 35px;
    width: 100%;
    padding-inline-start: 63px;
    input {
      margin-inline-end: 6px;
    }
    label {
      font-size: 26px;
      line-height: 20px;
      padding-bottom: 4px;
      cursor: pointer;
    }
  }
}
