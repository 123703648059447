@import "../../style/setup/variables.scss";

.indicator-popup {
  height: 63px;
  border-radius: $borderRadius;
  background-color: $clrWhite;
  box-shadow: 0px 0px 3px #323232c5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  right: 23px;
  bottom: 23px;
  z-index: 1301;
  padding: 0 12px;
  transition: 0.3s ease-in-out;
  .icn-wrapper {
    font-size: 35px;
    margin-inline-end: 5px;
    .success {
      color: $clrPositive;
    }
    .failure {
      color: $clrNegative;
    }
    .info {
      color: $clrNeutral;
    }
    .warning {
      color: $statusOrange;
    }
  }
  p {
    font-family: main-font-semi-bold;
    font-size: 30px;
  }
}
