@import "../../style/setup/variables.scss";

.clients-page {
  width: $mainPagesWidth;
  margin: 0 25px;
  .clients-grid-container {
    margin-top: 30px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 384px);
    gap: 70px;
  }
  .clientsTable {
    width: 65vw;
    margin-top: 10px;
  }
}
.add_client {
  font-size: 1.5rem;
  cursor: pointer;
  width: fit-content;
  direction: ltr;
  background: black;
  color: white;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 15px;
}
