@import "../../style/setup/variables.scss";

.meetings-page {
  width: 100%;
  margin: 0 25px;
  .meetings-grid-container {
    margin-top: 60px;
    width: 100%;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, 264px);
    gap: 80px;
  }
}

.meetingsTable {
  width: 100%;
}
