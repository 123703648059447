@import "../../style/setup/variables.scss";
.VideoPlayerComponent {
  margin-bottom: 10px;
  border-radius: $borderRadius;
  // overflow: hidden;
  &.floating {
    position: fixed;
    z-index: 10;
    top: 140px;
    left: 10px;
    cursor: move;
  }
  .videoPlayerContainer {
    position: relative;
    width: 100%;
    height: 350px;
    overflow: hidden;
    video {
      width: 100%;
      height: 100%;
      background-color: black;
    }
    .videoSubtitles {
      position: absolute;
      bottom: 18px;
      color: white;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 600;
      font-family: Arial, Helvetica, sans-serif;
      text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black,
        0 0 5px #000;
      direction: rtl;
      width: 100%;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      .liveCaptionEditing,
      .rangeString {
        margin-bottom: 3px;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        &.overlapping {
          color: red;
        }
        &.ltr {
          direction: ltr;
        }
        .passedWords {
          color: #007eff;
        }
      }
    }
  }
}
