@import "../../style/setup/variables.scss";

.search-bar {
  height: 42px;
  width: 220px;
  box-shadow: $cardShadow;
  border-radius: 21px;
  background-color: $clrWhite;
  input {
    margin-right: 10px;
    border: none;
    border-radius: 21px;
    font-family: main-font-light;
    font-size: 25px;
    padding: 0px;
    padding-bottom: 5px;
    color: $clrMainDark;
  }
  ::placeholder {
    color: $clrInputPlaceholder;
  }
  .icn-wrapper {
    padding: 10px;
    align-items: center;
    height: 100%;
    font-size: 19px;
  }
}
