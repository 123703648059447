@import "../../style/setup/variables.scss";

.Badge {
  position: relative;
  .badge {
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $clrMain;
    &.hidden {
      display: none;
    }
    &.near {
      &.rtl {
        left: 0;
      }
      &.ltr {
        right: 0;
      }
    }
    &.on {
      width: 10px;
      height: 10px;
    }
    &.rtl {
      transform: translateX(-100%);
    }
    &.ltr {
      transform: translateX(100%);
    }
  }
}
