@import "../../style/setup/variables.scss";
.approve-modal {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 22px 30px;
  padding-top: 34px;
  background-color: $clrWhite;
  transition: 0.3s;
  .modal-string {
    .meeting-name-p {
      margin: 5% 0;
      color: $clrMain;
      span {
        color: black;
      }
    }
    p {
      margin: 0;
      text-align: center;
    }
  }
  p {
    font-family: main-font-light;
    font-size: 26px;
    margin-bottom: 26px;
    span.roomName {
      color: $clrMain;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .btns {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1px;
  }
}
.cancelAbsolute {
  position: relative;
}
