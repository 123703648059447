@import "../../style/setup/variables.scss";

.members-sampling-cmp {
  width: $mainPagesWidth;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-family: main-font-light;
    font-size: 1.2rem;
    padding-bottom: 2px;
  }
  .header-section {
    margin-top: 28px;
    width: 100%;
    display: grid;
    margin-bottom: 70px;
  }
  .player-section {
    margin-bottom: 70px;
    width: 100%;
  }
  .slices-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .member {
      margin-bottom: 24px;
      display: flex;
      height: 74px;
      .name {
        width: 272px;
        min-width: 272px;
        margin-inline-end: 24px;
        input {
          border: none;
          outline: none;
          font-family: main-font-light;
          color: $clrMainDark;
          font-size: 26px;
          text-align: center;
        }
      }
      .time-slices {
        display: flex;
        height: 100%;
        .in-out-container {
          margin-inline-end: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          .blank {
            background-color: $clrDarkenedSoft;
            color: $clrWhite;
          }
        }
        .in-out-btns-container {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          button {
            width: 72px;
            min-width: 72px;
            background-color: $clrMainDark;
            color: $clrWhite;
            font-family: main-font-regular;
            font-size: 18px;
            padding-bottom: 3px;
          }
        }
      }
      &.positive {
        .name {
          background-color: $clrPositive;
          input {
            background-color: $clrPositive;
            color: $clrWhite;
          }
        }
      }
    }
  }
  .actions {
    width: 100%;
    display: flex;
    gap: 25px;
    .add-member {
      background-color: $clrMainDark;
      .icn-wrapper {
        padding-top: 4px;
        margin-inline-end: 20px;
      }
    }

    .finish-btn {
      background-color: $clrPositive;
    }
    button {
      margin-top: 10px;
      width: 272px;
      color: white;
      font-family: main-font-regular;
      font-size: 1.4rem;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .card {
    position: relative;
    width: 128px;
    min-width: 128px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: $clrWhite;
  }
  .remove-btn {
    cursor: pointer;
    position: absolute;
    z-index: 3;
    top: -13px;
    right: -12px;
    font-size: 26px;
    color: $clrNegative;
    cursor: pointer;
    transition: 0.3s;
  }
}
