@import "../../style/setup/variables.scss";
.pageHeaderContainer {
  display: flex;
  width: 100%;
  margin-bottom: 35px;
  .pageHeader {
    display: flex;
    flex-direction: row;
    text-align: center;
    font-size: 75px;
    .back-arrow {
      display: flex;
      cursor: pointer;
      align-self: center;
    }
    .clientName {
      cursor: pointer;
    }
  }
}

.meetings-page {
  width: 100%;
  margin: 0 25px;
  .meetings-grid-container {
    margin-top: 60px;
    width: 100%;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, 264px);
    gap: 80px;
  }
}

.meetingsTable {
  width: 100%;
}

.ClientMeetingsPage {
  .pageHeaderContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    .pageHeader {
      font-size: 2rem;
    }
  }
  .clientInfoContainer {
    display: grid;
    width: 100%;
    margin-bottom: 20px;
    column-gap: 64px;
    grid-template-columns: 1fr 2fr 1fr 4fr;
    .labelField {
      width: 300px;
      &.padding-right {
        padding-right: 100px;
      }
      height: 60px;
      .EditableText {
        font-size: 22px;
      }
    }
    .label {
      color: grey;
      font-size: 16px;
    }
    .note-container {
      width: 100%;
      height: auto;
      grid-column: 4/5;
      grid-row: 1/6;
      .notes {
        display: flex;
        flex-direction: column;
        height: 100%;
        .textarea-container {
          position: relative;
          height: 100%;
          .textarea-spinner {
            position: absolute;
            bottom: 10px;
            &.ltr {
              right: 10px;
            }
            &.rtl {
              left: 10px;
            }
          }
        }
        textarea {
          width: 100%;
          height: 100%;
          flex: 1;
          font-family: main-font-regular;
          font-size: 18px;
          resize: none;
          border-radius: 15px;
          border-color: grey;
          padding: 12px;
        }
      }
    }
    .vat {
      grid-row: 5/6;
    }
    .currentDebt {
      grid-row: 4/6;
      .field {
        font-weight: bold;
        font-size: 32px;
      }
    }
  }

  .docxExportPresets,
  .validationsTable {
    width: 100%;
    margin-bottom: 25px;
  }
}
