@import "../../style/setup/variables.scss";

$playerWidth: 55vw;

.media-player {
  // do not declare width here. media player width's define in MyMediaPlayer.tsx only!!
  direction: ltr;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  position: relative;
  z-index: 10;
  margin: 0 auto;
  .control-deck {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    padding: 8px 20px;
    z-index: 1;
    .playback {
      grid-column: 2;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .media-time-range {
        display: flex;
        width: 172px;
      }
      .curr-time {
        color: white;
        font: main-font-regular;
        font-size: 1.3rem;
        line-height: 1rem;
        color: $clrWhite;
      }
      .btns {
        width: 143px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $clrWhite;
        .skip5 {
          font-size: 22px;
          display: flex;
          flex-direction: row;
          gap: 3px;
          align-items: center;
          cursor: pointer;
          position: relative;
          p {
            font-size: 14px;
            line-height: 14px;
            bottom: 0px;
          }
        }
        .play-pause {
          display: flex;
          cursor: pointer;
          font-size: 30px;
        }
      }
    }
    .speed {
      grid-column: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      color: $clrWhite;
      .icn-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
      }
      .speed-control {
        width: 88px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .arrow {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          height: 9px;
          z-index: 1;
          cursor: pointer;
        }
        p {
          cursor: default;
          font-size: 22px;
          font-family: main-font-light;
          // line-height: 42px;
        }
      }
    }
    .viewModeIcon {
      width: 100%;
      text-align: right;
      svg {
        color: $clrWhite;
        font-size: 1.1rem;
        cursor: pointer;
        &:hover {
          color: $clrMain;
        }
      }
    }

    .media-source-management {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      color: $clrWhite;
      .icns-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100px;
        height: 100%;
        .icn {
          cursor: pointer;
        }
      }
    }
  }
  .timeline {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    background-color: $newBlue;
    // border-radius: $borderRadius;
    .marker-container {
      height: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      position: absolute;
      top: -13px;
      cursor: pointer;
      z-index: -1;
      .icns-wrapper {
        position: absolute;
        top: 0;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $clrMainDark;
        font-size: 20px;
        width: 19px;
        transform: translateY(-4px);
      }
      .seperator {
        height: 100%;
        width: 2px;
        background-color: $clrMainDark;
      }
    }
  }
  &.draggable {
    cursor: grab;
    &.dragging {
      cursor: grabbing;
    }
  }
  &.floating {
    position: fixed;
    bottom: 0;
    left: calc(50% - (#{$playerWidth} / 2));
  }
}
