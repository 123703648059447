@import "../../style/setup/variables.scss";

.Popup {
  display: flex;
  min-width: 200px;
  max-width: 300px;
  cursor: auto;
  flex-direction: column;
  top: 40px;
  border: $grayBorder;
  border-radius: $borderRadius;
  background: $whitesmoke;
  z-index: 11;
  position: absolute;
  padding: 6px 8px;
  .popupTitle {
    color: $gray;
  }
  .popupBody {
    align-self: center;
    width: 100%;
  }
}
