@import "../../style/setup/variables.scss";

$handleWidth: 4px;
$handleArrowColor: #000;
$startHandleLineColor: rgb(3, 182, 3);
$endHandleLineColor: rgb(175, 2, 2);

.WaveformRanges {
  width: 100%;
  height: $waveformHeight;
  position: relative;
  direction: ltr;
  padding: 10px;
  background: white;
  box-shadow: inset -1px 1px 0px #aeaeae, inset 0px -2px 0px #fff;
  .zoomContainer {
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    bottom: 0;
    top: 0;
    right: 15px;
    z-index: 10;
    margin: auto;
    align-items: center;
    box-shadow: 1px 1px 5px lightgrey;
    background: rgba(255, 255, 255, 0.8);
    height: fit-content;
    font-size: inherit;
    .zoomButton {
      padding: 5px;
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
    }
    .zoomValue {
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      padding: 0 10px;
    }
  }
  #waveformWrapper {
    // Overrides inline css
    showtitle {
      top: unset !important;
      bottom: -15px !important;
    }
    .waveformContainer {
      .wavesurfer-region {
        z-index: 5 !important;
        border-left: $handleWidth solid $clrMain;
        border-right: $handleWidth solid $clrMain;
        handle {
          width: $handleWidth !important;
          background-color: transparent !important;
          &:nth-child(odd) {
            left: -$handleWidth !important;
          }
          &:nth-child(even) {
            right: -$handleWidth !important;
          }
        }
        &::before,
        &::after {
          width: 100%;
          position: absolute;
          padding: 2px 5px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          background: #e8e8e8;
          direction: rtl;
          text-align: right;
          font-size: 16px;
          box-sizing: border-box;
          z-index: 1000;
        }
        &::before {
          content: attr(data-region-rangetimes);
          top: 0px;
          text-align: left;
          font-size: 12px;
          color: $clrNoData2;
          background: transparent;
        }
        &:nth-child(odd) {
          background-color: rgba(231, 216, 180, 0.3) !important;
        }
        &:nth-child(even) {
          background-color: rgba(158, 182, 213, 0.3) !important;
        }
      }
    }
    visibility: visible;
    opacity: 1;
    height: auto;
    transition: all 0.2s ease-out;
    &.hidden {
      visibility: hidden;
      opacity: 0;
      height: 0;
    }
  }
  .loaderContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .BarLoader {
      width: 280px;
      height: 140px;
    }
  }
}
