@import "../../style/setup/variables.scss";
#editorContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - #{$topBarHeight});
  &.waveform {
    height: calc(100vh - #{$topBarHeight} - #{$waveformHeight});
  }
}
.editor-page {
  width: 95vw;
  max-width: 100%;
  min-width: 1000px;
  margin: 0 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 230px;
  font-family: main-font-light;
  font-weight: lighter;
  .headerSection {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    .editorHeader {
      display: flex;
      justify-content: space-between;
      .roomName {
        font-size: 3.5rem;
        margin-bottom: 20px;
      }
      .transcriberAndProoferName {
        align-self: center;
        font-size: 2rem;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        a {
          text-decoration: none;
        }
      }
    }
    .readOnly {
      font-size: 1.5rem;
      color: #555555;
      font-weight: 700;
    }
    .detailsContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      background-color: $clrMainDark;
      font-size: 1.4rem;
      color: $clrWhite;
      position: relative;
      span {
        font-family: main-font-light;
        font-weight: lighter;
      }
      .roomMetadata {
        display: flex;
        gap: 50px;
        .detail-wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .icn-wrapper {
            color: $clrWhite;
            margin-inline-end: 8px;
          }
        }
      }
      .roomActions {
        display: flex;
        gap: 30px;
        .actionButton {
          display: flex;
          align-items: center;
          gap: 5px;
          font-size: 1.1rem;
          background: none;
          color: $clrWhite;
          &:hover {
            color: $clrMain;
          }
          &:focus {
            color: $clrMain;
          }
        }
      }
    }

    .speakersContainer,
    .subtitlesActionsContainer,
    .protocolActionsContainer {
      flex-flow: wrap;
      width: 100%;
      background-color: $clrWhite;
      margin-top: 1.25vh;
      padding: 10px;
      box-shadow: 0px 2px 0px $clrMainDark;
      display: flex;
      .speaker {
        margin-left: 15px;
        font-size: 18px;
        cursor: pointer;
        &:hover {
          opacity: 0.6;
        }
      }
    }

    .subtitlesActionsContainer,
    .protocolActionsContainer {
      flex-direction: row;
      justify-content: flex-end;
      gap: 30px;
      .action {
        display: flex;
        flex-direction: row;
        flex-flow: row-reverse;
        gap: 5px;
        cursor: pointer;
        &.timestampsEnterMethod {
          .icon {
            margin: 0 5px;
            transform: rotate(90deg);
          }
        }
        &.disabled {
          opacity: 0.3;
          &:hover {
            cursor: default;
            opacity: 0.3;
          }
        }
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  .multiRanges {
    .editorRanges {
      flex: 3;
    }
    .editorPlayer {
      flex: 2;
      .audioList {
        width: 100%;
        background: white;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        .audioFilesTitle {
          display: flex;
          padding-right: 16px;
        }
        .audios {
          display: flex;
          padding-right: 16px;
          overflow-x: scroll;
        }
        .audio {
          display: flex;
          margin-left: 15px;
          cursor: pointer;
        }
        & .current {
          color: red;
        }
      }
    }
  }
  .editorSection {
    width: 100%;
    display: flex;
    gap: 20px;
    position: relative;
    .editorRanges {
      width: 100%;
      .textContainer {
        width: 100%;
      }
    }
    &.splitView {
      .editorRanges {
        width: 70%;
      }
      .editorPlayer {
        width: 30%;
        height: 500px;
        display: flex;
        position: sticky;
        top: 10px;
        flex-direction: column;
        align-items: center;
        .VideoPlayerComponent {
          width: 100%;
          margin-bottom: 20px;
        }
        .videoPlayerContainer {
          width: 100%;
          height: auto;
          aspect-ratio: 1.6;
        }
        .audioList {
          width: 100%;
          background: white;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          .audioFilesTitle {
            display: flex;
            padding-right: 16px;
          }
          .audios {
            display: flex;
            padding-right: 16px;
            overflow-x: scroll;
          }
          .audio {
            display: flex;
            margin-left: 15px;
            cursor: pointer;
          }
          & .current {
            color: red;
          }
        }
      }
    }
  }

  &.ltr {
    .speakersContainer {
      direction: ltr;
    }

    .editorSection {
      direction: ltr;
    }
  }
}

.jobSplitModal {
  .bodyContainer {
    .countSelect {
      display: flex;
      align-items: baseline;
      gap: 10px;
      .Input {
        width: 100px;
      }
    }
  }
}

.restoreWordsModal {
  .SelectList {
    width: 500px;
  }
}
